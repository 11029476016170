.App {
  text-align: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: 20s linear infinite App-logo-spin;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #fff;
}
.App-link {
  color: #61dafb;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
.readoncookie {
  background-color: #00a3e8;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  padding: 2px 4px;}
.cookiebanner {
  position: fixed;
  bottom: 0;
  background: #000;
  z-index: 99;
  position: fixed;
  bottom: 0;
  background: #000000d9;
  z-index: 99;
  -webkit-animation: 1.2s ease-in-out fadeInUp;
  animation: 1.2s ease-in-out fadeInUp;
}
.cookiepara {
  color: #fff;
  padding: 8px 10px;
  margin: 10px;
  font-size: 15px;
}
.cokkiebtn {
  float: right;
  margin-right: 20px;
  margin-bottom: 20px;
}
.cookiepvy {
  color: #00a3e8;
}
body{
  margin-top:20px;
  background:#eee;
}
.card::after {
  display: block;
  position: absolute;
  bottom: -10px;
  left: 20px;
  width: calc(100% - 40px);
  height: 35px;
  background-color: #fff;
  -webkit-box-shadow: 0 19px 28px 5px rgba(64,64,64,0.09);
  box-shadow: 0 19px 28px 5px rgba(64,64,64,0.09);
  content: '';
  z-index: -1;
}
a.card {
  text-decoration: none;
}

.card {
  position: relative;
  border: 0;
  border-radius: 0;
  background-color: #fff;
  -webkit-box-shadow: 0 12px 20px 1px rgba(64,64,64,0.09);
  box-shadow: 0 12px 20px 1px rgba(64,64,64,0.09);
}
.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0,0,0,0.125);
  border-radius: .25rem;
}

.box-shadow {
  -webkit-box-shadow: 0 12px 20px 1px rgba(64,64,64,0.09) !important;
  box-shadow: 0 12px 20px 1px rgba(64,64,64,0.09) !important;
}

.ml-auto, .mx-auto {
  margin-left: auto !important;
}
.mr-auto, .mx-auto {
  margin-right: auto !important;
}
.rounded-circle {
  border-radius: 50% !important;
}
.bg-white {
  background-color: #fff !important;
}

.ml-auto, .mx-auto {
  margin-left: auto !important;
}

.mr-auto, .mx-auto {
  margin-right: auto !important;
}
.d-block {
  display: block !important;
}
img, figure {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

.card-text {
  padding-top: 12px;
  color: #8c8c8c;
}

.text-sm {
  font-size: 12px !important;
}
p, .p {
  margin: 0 0 16px;
}

.card-title {
  margin: 0;
  font-family: "Montserrat",sans-serif;
  font-size: 18px;
  font-weight: 900;
}

.pt-1, .py-1 {
  padding-top: .25rem !important;
}

.head-icon{
  margin-top:18px;
  color:#FF4500
}
.fireright{
  color:#FF4500

}
a{
  text-decoration:none;
}
.floating_btn {
  position: fixed;
  bottom: 62px;
  right: 10px;
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items:center;
  justify-content:center;
  z-index: 1000;
}

@keyframes pulsing {
  to {
    box-shadow: 0 0 0 30px rgba(232, 76, 61, 0);
  }
}

.contact_icon {
  background-color: #42db87;
  color: #fff;
  width: 60px;
  height: 60px;
  font-size:30px;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translatey(0px);
  animation: pulse 1.5s infinite;
  box-shadow: 0 0 0 0 #42db87;
  -webkit-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -ms-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  font-weight: normal;
  font-family: sans-serif;
  text-decoration: none !important;
  transition: all 300ms ease-in-out;
}


.text_icon {
  margin-top: 8px;
  color: #707070;
  font-size: 13px;
}