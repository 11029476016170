.web-dev-heading-div{
    display: flex;
    flex-direction: column;
    width: 100vw;
    padding: 2% 5%;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.web-dev-heading-title{
    padding: 0px 5px;
    line-height: 1.2;
    font-size: 2.2rem;
    font-weight: bold;
}
.web-dev-heading-context{
    font-size: 1.1rem;
      font-weight: 500;
    line-height: 1.1;
    /* font-weight: lighter; */
}

@media only screen and (max-width: 600px) {
    .web-dev-heading-div {
      flex-direction: column;
      padding: 5%;
    }
  
    .web-dev-heading-title {
      font-size: 1.8rem;
      text-align: center;
    }
  
    .web-dev-heading-context {
      /* font-size: 0.5rem; */
      text-align: center;
    }
  }

  @media only screen and (min-width: 601px) and (max-width: 768px) {
    .web-dev-heading-div {
      flex-direction: column;
      padding: 5%;
    }
  
    .web-dev-heading-title {
      font-size: 2rem;
      text-align: center;
    }
  
    .web-dev-heading-context {
      /* font-size: 0.5rem; */
      text-align: center;
    }
  }
  
  @media only screen and (min-width: 769px) and (max-width: 992px) {
    .web-dev-heading-div {
      padding: 4%;
    }
  
    .web-dev-heading-title {
      font-size: 2.1rem;
    }
  
    .web-dev-heading-context {
      /* font-size: 0.5rem; */
    }
  }
  
  @media only screen and (min-width: 993px) and (max-width: 1200px) {
    .web-dev-heading-div {
      padding: 3% 5%;
    }
  
    .web-dev-heading-title {
      font-size: 2.2rem;
    }
  
    .web-dev-heading-context {
      /* font-size: 0.5rem; */
    }
  }
  
  @media only screen and (min-width: 1201px) {
    .web-dev-heading-div {
      padding: 2% 5%;
    }
  
    .web-dev-heading-title {
      font-size: 2.6rem;
    }
  
    .web-dev-heading-context {
      width: 60%;
      /* font-size: 1.1rem;
      font-weight: 500; */
    }
  }