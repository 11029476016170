@charset "utf-8";
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800,900&display=swap);
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0 0 26px;
}
.sec-title .title span,
.sec-title2 .title span {
  font-style: oblique;
  color: #00a3e8;
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
html {
  font-family: Montserrat, sans-serif;
  color: #0a0a0a;
}
a,
button:focus,
input:focus,
select:focus,
textarea:focus {
  outline: 0 !important;
}
.rs-carousel.nav-style2 .owl-nav .owl-next i,
.rs-carousel.nav-style2 .owl-nav .owl-prev i,
a {
  transition: 0.3s;
}
.full-width-header .mobile-navbar-menu li:hover > ul > li,
.full-width-header .mobile-navbar-menu ul ul li,
img {
  height: auto;
}
#scrollUp i,
h4 {
  line-height: 36px;
}
body,
html {
  font-size: 15px;
  vertical-align: baseline;
  line-height: 1.7;
  font-weight: 600;
  overflow-x: hidden;
}
img {
  max-width: 100%;
}
p {
  line-height: 1.8;
  font-weight: 500;
}
.rs-single-shop .single-price-info .tag span,
h1,
h2,
h3,
h4 {
  font-weight: 700;
}
.full-width-header .right_menu_togle .canvas-contact .social li:last-child,
.full-width-header
  .rs-header
  .menu-area
  .main-menu
  .rs-menu
  ul.sub-menu
  li:last-child,
.glyph-icon:before,
.rs-estimate .estimate-info li:last-child,
.rs-footer .bg-wrap .footer-content .about-widget .social-links li:last-child,
.rs-footer .bg-wrap .footer-content .widget ul li:last-child,
.rs-footer.style1 .footer-top .footer-social li:last-child,
.rs-iconbox-area .box-inner .box-item .text-area .services-txt,
.rs-services.style1.modify,
.rs-services.style3 .services-item .services-content .services-txt,
.rs-single-shop .single-price-info .tag,
.rs-single-shop .tab-area .tab-content .tab-pane .dsc-p,
.rs-slider.slider3 .content-part .slider-bottom ul li:last-child,
.rs-testimonial.style1 .item .rating li:last-child,
[class*=" flaticon-"]:after,
[class*=" flaticon-"]:before,
[class^="flaticon-"]:after,
[class^="flaticon-"]:before,
ul {
  margin: 0;
}
h1 {
  font-size: 30px;
  line-height: 40px;
}
h2 {
  font-size: 40px;
  line-height: 50px;
}
h3 {
  font-size: 28px;
  line-height: 42px;
}
h4 {
  font-size: 24px;
}
h5 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
}
h6 {
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
}
.pt-95 {
  padding-top: 95px;
}
.pt-50 {
  padding-top: 50px;
}
.pb-95 {
  padding-bottom: 95px;
}
.rs-testimonial.style3 .testi-item .testi-content .author-part,
.text-md-left {
  text-align: left;
}
.rs-menu-toggle,
.social ul li,
.text-md-right,
.text-right {
  text-align: right;
}
.no-border {
  border: none !important;
}
.uppercase {
  text-transform: uppercase !important;
}
.capitalize {
  text-transform: capitalize !important;
}
.extra-bold {
  font-weight: 800 !important;
}
.title-color {
  color: #0a0a0a !important;
}
.body-color {
  color: #454545 !important;
}
.full-width-header .rs-header .menu-area.sticky,
.white-bg {
  background: #fff;
}
.home-6
  .full-width-header
  .rs-header
  .menu-area
  .main-menu
  .rs-menu
  ul
  li
  a:hover,
.home-6
  .full-width-header
  .rs-header
  .menu-area
  .main-menu
  .rs-menu
  ul
  li.active
  a,
.home-6
  .full-width-header
  .rs-header
  .menu-area
  .main-menu
  .rs-menu
  ul
  li.current-menu-item
  > a,
.home-6
  .full-width-header
  .rs-header
  .menu-area
  .main-menu
  .rs-menu
  ul
  li.current-menu-item
  > a::before,
.primary-color2 {
  color: #e94d65 !important;
}
.primary-bd2 {
  background: #e94d65 !important;
}
.secondary-bg2 {
  background: #064491 !important;
}
.pt-133 {
  padding-top: 35px;
}
.pb-133,
.pb-25 {
  padding-bottom: 25px;
}
.rs-vertical-bottom,
.y-bottom {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: flex-end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
}
.rs-vertical-middle,
.y-middle {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
a {
  text-decoration: none !important;
  color: #00a3e8;
}
a:active,
a:hover {
  text-decoration: none;
  outline: 0;
  color: #00a3e8;
}
ul {
  list-style: none;
  padding: 0;
}
.clear {
  clear: both;
}
::-moz-selection {
  background: #00a3e8;
  text-shadow: none;
  color: #fff;
}
::selection {
  background: #00a3e8;
  text-shadow: none;
  color: #fff;
}
.bg11,
.bg9 {
  background-position: top center;
}
.bg11,
.bg17,
.bg25,
.bg26,
.bg9 {
  background-repeat: no-repeat;
  background-size: cover;
}
.bg9 {
  background-image: url(../../public/images/contact-bg-3.jpg);
}
.bg11 {
  background-image: url(../../public/images/contact-bg-2.jpg);
}
.bg17 {
  background-image: url(../../public/images/team-bg3.jpg);
}
.bg25 {
  background-image: url(../../public/images/clientback.jpg);
}


.bg26 {
  /* background-image: url(../../public/images/fireclientbanner.PNG); */
  background-image: url(../../public/images/clientbanner8.png);

}


body.nav-expanded .offwrap {
  left: 0;
  transition: 0.8s ease-out;
}
.bg-unset {
  background: unset !important;
}
.full-width-header .mobile-menu .rs-menu-toggle.primary,
.full-width-header .mobile-navbar-menu .nav-menu li a:hover,
.full-width-header .mobile-navbar-menu .nav-menu li.active-menu a,
.full-width-header
  .right_menu_togle
  .canvas-contact
  .address-area
  .address-list
  .info-content
  em
  a:hover,
.full-width-header .rs-header .expand-btn-inner ul li > a:hover,
.full-width-header
  .rs-header
  .menu-area
  .main-menu
  .rs-menu
  ul.nav-menu
  li
  a:hover,
.full-width-header
  .rs-header
  .menu-area
  .main-menu
  .rs-menu
  ul.nav-menu
  li.current-menu-item
  > a,
.full-width-header
  .rs-header
  .menu-area
  .main-menu
  .rs-menu
  ul.onepage-menu
  li.current-menu-item
  > a,
.full-width-header
  .rs-header
  .menu-area
  .main-menu
  .rs-menu
  ul.sub-menu
  li
  a:hover,
.full-width-header
  .rs-header
  .menu-area.sticky
  .expand-btn-inner
  ul
  .rsphone
  a:hover,
.full-width-header
  .rs-header
  .menu-area.sticky
  .expand-btn-inner
  ul
  .search-parent
  a
  i:hover,
.full-width-header
  .rs-header
  .menu-area.sticky
  .main-menu
  .rs-menu
  ul.nav-menu
  > li.current-menu-item
  > a,
.full-width-header
  .rs-header
  .menu-area.sticky
  .main-menu
  .rs-menu
  ul.nav-menu
  > li
  > a:hover,
.job-salary a:hover,
.job-salary a:hover i,
.rs-carousel.nav-style2 .owl-nav .owl-next:hover i,
.rs-carousel.nav-style2 .owl-nav .owl-prev:hover i,
.rs-contact.contact-style2 .contact-address .address-item .address-text a:hover,
.rs-contact.faq-style .contact-item .content-text a:hover,
.rs-contact.main-home
  .contact-icons-style
  .contact-item
  .content-text
  .title
  a:hover,
.rs-contact.main-home .contact-icons-style .contact-item .content-text a:hover,
.rs-contact.main-home
  .contact-icons-style.box-address
  .contact-item
  .content-text
  .title
  a:hover,
.rs-contact.main-home
  .contact-icons-style.box-address
  .contact-item
  .content-text
  a:hover,
.rs-contact.main-home.office-modify1 .contact-box .content-text .title a:hover,
.rs-contact.main-home.office-modify1 .contact-box .content-text a:hover,
.rs-footer.style1 .footer-bottom .copy-right-menu li a:hover,
.rs-footer.style1 .footer-top .address-widget li .desc a:hover,
.rs-footer.style1.footer-home3-style .footer-bottom .copy-right-menu li a:hover,
.rs-footer.style1.footer-home3-style
  .footer-top
  .address-widget
  li
  .desc
  a:hover,
.rs-footer.style1.footer-home3-style .footer-top .address-widget li i,
.rs-footer.style1.footer-home3-style .footer-top .site-map li a:hover,
.rs-footer.style1.footer-home4-style .footer-bottom .copy-right-menu li a:hover,
.rs-footer.style1.footer-home4-style
  .footer-top
  .address-widget
  li
  .desc
  a:hover,
.rs-footer.style1.footer-home4-style .footer-top .address-widget li i,
.rs-footer.style1.footer-home4-style .footer-top .site-map li a:hover,
.rs-menu-toggle,
.rs-services-single .call-us .contact-widget .title a:hover,
.rs-services-single .widget-item li a:hover i,
.rs-services-single .widget-item li a:hover span,
.rs-services.chooseus-style .services-item .services-text .title a:hover,
.rs-services.home-style2 .services-item .services-text .title a:hover,
.rs-services.main-home .services-item .services-text .title a:hover,
.rs-services.main-home.services-style1
  .services-item
  .services-text
  .title
  a:hover,
.rs-services.main-home.services-style1.home-4-style
  .services-item
  .services-text
  .title
  a:hover,
.rs-services.style2 .service-wrap .content-part .title a:hover,
.rs-services.style3 .services-item .services-content .services-title a:hover,
.rs-services.style4 .services-item .services-content .title a:hover,
.rs-services.style5
  .flip-box-inner
  .flip-box-wrap
  .front-part
  .front-content-part
  .front-title-part
  .title
  a:hover,
.rs-services.style6
  .services-box-area
  .services-item
  .services-content
  .title
  a:hover,
.rs-services.style8
  .flip-box-inner
  .flip-box-wrap
  .front-part
  .front-content-part
  .front-title-part
  .title
  a:hover,
.rs-shop-part .product-list .content-desc .loop-product-title a:hover,
.rs-single-shop .single-price-info p.category a:hover,
.rs-slider.rs-slider-style3 .nivo-directionNav > a:before,
.rs-team-Single
  .btm-info-team
  .con-info
  .ps-informations
  .personal-info
  li
  a:hover,
.rs-team-Single
  .btm-info-team
  .con-info
  .ps-informations
  .social-info
  li
  a:hover,
.rs-team.style1 .team-wrap .team-content .team-name a:hover,
.rs-team.style1.team-style1 .team-wrap .team-content .team-name a:hover,
.rs-team.style3 .team-item .team-content .social-icon li a i:hover,
.rs-team.style3 .team-item .team-content .team-info .name a:hover,
.rs-team.style4 .team-item-wrap .team-content .person-name a:hover,
.rs-team.style4.home-4-team-style
  .team-item-wrap
  .team-content
  .person-name
  a:hover,
.single-choose-card .choose-content h3 a,
.term12 h5,
.widget-area .widget_categories .list li a:hover {
  color: #00a3e8;
}
button {
  cursor: pointer;
}
.job-instructor-img-2,
.rs-cta .readon2,
.rs-cta .sec-title4,
.sec-title4,
.z-index-1 {
  position: relative;
  z-index: 1;
}
ul.services-list li a {
  display: block;
  border: 1px solid #e8e8e8;
  padding: 16px 18px;
  transition: 0.5s;
  position: relative;
  font-size: 16px;
  font-weight: 700;
  color: #0a0a0a;
  margin-bottom: 15px;
}
ul.services-list li a:before {
  content: "\f113";
  position: absolute;
  right: 12px;
  top: 16px;
  font-family: Flaticon;
  color: #1c1b1b;
}
.full-width-header .right_menu_togle .canvas-contact .social li a i:hover,
.readon.price.price2,
.rs-single-shop .tab-area ul.nav-tabs li .nav-link.active,
.rs-single-shop .tab-area ul.nav-tabs li .nav-link:hover,
ul.services-list li a.active,
ul.services-list li a:hover {
  background: #00a3e8;
  color: #fff;
}
.full-width-header
  .right_menu_togle
  .canvas-contact
  .address-area
  .address-list
  .info-content
  em
  a,
.rs-contact.main-home .contact-icons-style .contact-item .content-text .title a,
.rs-footer .bg-wrap .footer-content .about-widget,
.rs-footer.style1 .footer-bottom .copy-right-menu li a,
.rs-footer.style1 .footer-top .address-widget li .desc,
.rs-footer.style1 .footer-top .address-widget li .desc a,
.rs-services-single .call-us .contact-widget .title a,
.rs-services.chooseus-style .services-item .services-text .title a,
.rs-services.main-home .services-item .services-text .title a,
.rs-services.style5
  .flip-box-inner
  .flip-box-wrap
  .back-part
  .back-front-content
  .back-title-part
  .back-title
  a,
.rs-services.style5
  .flip-box-inner:hover
  .flip-box-wrap
  .back-part
  .back-front-content
  .back-desc-part
  .back-desc,
.rs-services.style5
  .flip-box-inner:hover
  .flip-box-wrap
  .back-part
  .back-front-content
  .back-title-part
  .back-title,
.rs-services.style8
  .flip-box-inner
  .flip-box-wrap
  .back-front
  .back-front-content
  .back-title-part
  .back-title
  a,
.rs-services.style8
  .flip-box-inner:hover
  .flip-box-wrap
  .back-front
  .back-front-content
  .back-desc-part
  .back-desc,
.rs-services.style8
  .flip-box-inner:hover
  .flip-box-wrap
  .back-front
  .back-front-content
  .back-title-part
  .back-title,
.rs-slider.slider3 .nivo-directionNav > a:before,
.rs-team.style1 .team-wrap .team-content .team-name a,
.rs-team.style1.team-style1 .team-wrap .team-content .team-name a,
.rs-team.style2 .team-item:hover .team-content .team-info .name a,
.rs-team.style2 .team-item:hover .team-content .team-info .post,
.rs-testimonial.style4 .testi-item .testi-wrap .testi-information .testi-title,
.social ul li:hover a,
.technology-item:hover .bodytech a,
.technology-item:hover .bodytech h4,
.technology-item:hover .bodytech h5,
.technology-item:hover .bodytech p,
ul.services-list li a.active:before,
ul.services-list li a:hover:before {
  color: #fff;
}
ul.listing-style li {
  margin-bottom: 7px;
}
ul.listing-style li i {
  color: #00a3e8;
  font-size: 24px;
  line-height: 31px;
  margin-right: 10px;
}
.features-content {
  padding: 98px 150px 97px 100px;
}
.features-content ul.rs-features-list li {
  margin: 0 0 21px;
  display: flex;
  align-items: center;
}
.features-content ul.rs-features-list li i {
  color: #fff;
  background-color: transparent;
  background-image: linear-gradient(180deg, #e78b0e 0, #d22511 100%);
  padding: 15px;
  margin: 0 25px 0 0;
  border-radius: 50px;
}
.features-content ul.rs-features-list li .feature-text {
  color: #000;
  font-weight: 500;
}
.icon-item li {
  display: flex;
  padding-bottom: 10px;
}
.icon-item li .list-icon i {
  font-size: 14px;
  width: 1.25em;
}
.icon-item li .list-text {
  align-self: center;
  padding-left: 5px;
}
.icon-item li:last-child {
  padding-bottom: 0;
}
.dots {
  list-style: disc;
  margin: 0 0 1.5em 3em;
}
.arrow-btn {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -15%);
}
.arrow-btn a {
  font-weight: 400;
  font-size: 17px;
  border: 1px solid #00a3e8;
  margin: 0;
  border-radius: 23px;
  padding: 12px 15px;
  color: #00a3e8;
}
.arrow-btn a span {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  margin: -4px 0 0 -4px;
  border-radius: 50%;
  background: #00a3e8;
}
.add-btn,
.blog-button a,
.full-width-header,
.full-width-header .mobile-navbar-menu .nav-menu li,
.full-width-header .rs-header,
.left-line-v,
.readon.submit,
.readon2,
.readon2 .btn-arrow,
.relative,
.rs-carousel.nav-style1,
.rs-carousel.nav-style2,
.rs-services.main-home.services-style1 .services-item,
.rs-shop-part .product-list .image-product,
.rs-slider.slider3,
.rs-team-Single .btm-info-team .con-info,
.sec-title .title,
.sec-title3,
.sec-title3 .heading-border-line,
.sec-title3 .sub-text,
.sec-title3:hover .heading-border-line,
.service-d-img,
.submit-btn,
.technology-detail-top,
body .search-modal .search-block form {
  position: relative;
}
.blog-card .blog-image a,
.rs-carousel.nav-style1 .owl-nav,
.single-blog-card .blog-image a,
.single-choose-card .choose-image a {
  display: block;
}
.rs-carousel.nav-style1 .owl-nav .owl-next,
.rs-carousel.nav-style1 .owl-nav .owl-prev {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 30px;
  width: 42px;
  height: 42px;
  line-height: 42px;
  border-radius: 50%;
  background: #00a3e8;
  text-align: center;
  color: #fff;
  transition: 0.5s 0.7s;
  opacity: 0;
  visibility: hidden;
}
.rs-carousel.nav-style1 .owl-nav .owl-next i:before,
.rs-carousel.nav-style1 .owl-nav .owl-prev i:before {
  content: "\f138";
  font-family: Flaticon;
}
.rs-carousel.nav-style1 .owl-nav .owl-next {
  right: 30px;
  left: unset;
}
.rs-carousel.nav-style1 .owl-nav .owl-next i:before {
  content: "\f137";
}
.rs-carousel.nav-style1:hover .owl-nav .owl-next,
.rs-carousel.nav-style1:hover .owl-nav .owl-prev {
  left: -50px;
  transition-delay: 0s;
  visibility: visible;
  opacity: 1;
}
.rs-carousel.nav-style1:hover .owl-nav .owl-next {
  right: -50px;
  left: unset;
}
.rs-carousel.nav-style2 .owl-nav {
  display: block;
  position: absolute;
  top: -80px;
  right: 0;
}
.add-btn,
.blog-button a,
.full-width-header .right_menu_togle .canvas-contact .social li,
.our-team .icon11 li,
.rs-carousel.nav-style2 .owl-nav .owl-next,
.rs-carousel.nav-style2 .owl-nav .owl-prev,
.rs-partner .item a,
.rs-slider.slider3 .content-part .slider-bottom,
.sub-text,
.submit-btn {
  display: inline-block;
}
.rs-carousel.nav-style2 .owl-nav .owl-next i:before,
.rs-carousel.nav-style2 .owl-nav .owl-prev i:before {
  font-family: Flaticon;
  font-size: 22px;
}
.rs-carousel.nav-style2 .owl-nav .owl-prev i:before {
  content: "\f134";
}
.rs-carousel.nav-style2 .owl-nav .owl-prev:after {
  content: "/";
  padding: 0 5px;
  position: relative;
  top: -3px;
}
.rs-carousel.nav-style2 .owl-nav .owl-next i:before {
  content: "\f133";
}
.rs-carousel .owl-dots {
  text-align: center;
  margin-top: 50px;
}
.rs-carousel .owl-dots .owl-dot {
  width: 25px;
  height: 8px;
  display: inline-block;
  margin: 0 6px;
  padding: 3px 0;
  border-radius: 0;
  border: none;
  transition: 0.3s;
  background: #00a3e8;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.25);
}
.rs-carousel .owl-dots .owl-dot.active,
.rs-carousel .owl-dots .owl-dot:hover {
  width: 40px;
  background: #00a3e8;
  opacity: 0.5;
}
.project-item .vertical-middle {
  position: relative;
  z-index: 9;
}
.display-table,
.vertical-middle {
  display: table;
  height: 100%;
  width: 100%;
}
.display-table-cell,
.vertical-middle-cell {
  display: table-cell;
  vertical-align: middle;
}
.readon {
  color: #fff;
  font-size: 17px;
  line-height: 26px;
  font-weight: 600;
  text-transform: capitalize;
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.4s;
  z-index: 1;
  background-color: transparent;
}
.readon.consultant {
  padding: 18px 35px;
  border-radius: 0;
  color: #fff;
  background: #00a3e8;
  overflow: hidden;
  position: relative;
}
.readon.consultant:after {
  content: "";
  position: absolute;
  top: 10px;
  left: 0;
  width: 300px;
  height: 200px;
  background-color: #333;
  border-color: transparent;
  border-radius: 50%;
  transform: translate(-40px, -80px) scale(0.1);
  opacity: 0;
  z-index: -1;
  transition: transform 0.5s, opacity 0.5s, background-color 0.5s;
}
.readon.consultant:hover:after {
  opacity: 0.3;
  transform-origin: 100px 100px;
  transform: scale(1) translate(-10px, -70px);
}
.readon.consultant.slider {
  padding: 18px 40px;
}
.readon.consultant.discover,
.readon.consultant.paste-btn.more {
  padding: 15px 40px;
}
#scrollUp.blue-color i,
#scrollUp.orange2-color i,
.full-width-header .right_menu_togle .close-btn:hover .nav-close .line span,
.full-width-header
  .rs-header
  .expand-btn-inner
  ul
  .humburger
  .nav-expander
  .bar
  span.dot2,
.full-width-header
  .rs-header
  .expand-btn-inner
  ul
  .humburger
  .nav-expander
  .bar
  span.dot3,
.full-width-header
  .rs-header
  .expand-btn-inner
  ul
  .humburger
  .nav-expander
  .bar
  span.dot4,
.full-width-header
  .rs-header
  .expand-btn-inner
  ul
  .humburger
  .nav-expander
  .bar
  span.dot5,
.full-width-header
  .rs-header
  .expand-btn-inner
  ul
  .humburger
  .nav-expander
  .bar
  span.dot6,
.full-width-header
  .rs-header
  .expand-btn-inner
  ul
  .humburger
  .nav-expander
  .bar
  span.dot7,
.full-width-header
  .rs-header
  .expand-btn-inner
  ul
  .humburger
  .nav-expander
  .bar
  span.dot8,
.full-width-header
  .rs-header
  .menu-area.sticky
  .expand-btn-inner
  ul
  .nav-link
  .nav-expander:hover
  .bar
  span,
.home-blue-color .rs-carousel .owl-dots .owl-dot,
.home-blue-color .rs-carousel .owl-dots .owl-dot.active,
.home-blue-color .rs-carousel .owl-dots .owl-dot:hover,
.home-blue-color .search-modal,
.home-orange-color .rs-carousel .owl-dots .owl-dot,
.home-orange-color .rs-carousel .owl-dots .owl-dot.active,
.home-orange-color .rs-carousel .owl-dots .owl-dot:hover,
.home-orange-color .search-modal,
.readon.consultant.discover.orange-more,
.readon.consultant.orange-slide,
.rs-footer.style1.footer-home3-style .footer-bottom .copy-right-menu li:before,
.rs-footer.style1.footer-home3-style .footer-top .footer-social li a i:hover,
.rs-footer.style1.footer-home3-style .footer-top .footer-title:after,
.rs-footer.style1.footer-home3-style .footer-top p input[type="submit"],
.rs-footer.style1.footer-home4-style .footer-bottom .copy-right-menu li:before,
.rs-footer.style1.footer-home4-style .footer-top .footer-social li a i:hover,
.rs-footer.style1.footer-home4-style .footer-top .footer-title:after,
.rs-footer.style1.footer-home4-style .footer-top p input[type="submit"],
.rs-services.main-home.services-style1.home-4-style .services-item:after {
  background: #00a3e8;
}
.readon.consultant.blue-view {
  padding: 15px 40px;
  background: #00a3e8;
}
.readon.consultant.blog {
  font-size: 14px;
  font-weight: 600;
  padding: 9px 50px 9px 25px;
  background: #00a3e8;
  position: relative;
}
.readon.consultant.blog:before {
  content: "\f114";
  font-family: Flaticon;
  font-size: 15px;
  font-weight: 900;
  position: absolute;
  top: 11px;
  right: 26px;
  transition: 0.4s;
  color: #fff;
}
.readon.consultant.lets-talk {
  padding: 8px 22px;
  font-size: 14px;
  background: #00a3e8;
}
.readon.consultant.get-quote {
  font-size: 14px;
  padding: 8px 22px;
  background: #4caf50;
  border-color: #4caf50;
}
.readon.consultant.paste-btn {
  font-weight: 500;
  padding: 15px 33px;
  background-color: #4caf50;
  border-radius: 4px;
}
.readon.blue-slide {
  background-color: #00a3e8;
  color: #fff;
  padding: 17px 35px;
  display: inline-block !important;
}
#scrollUp i:hover,
#scrollUp.blue-color i:hover,
#scrollUp.orange2-color i:hover,
#scrollUp.paste-color i:hover,
.readon.blue-slide:hover {
  opacity: 0.8;
}
.readon.submit {
  background-color: #00a3e8;
  padding: 8px 35px;
  border: none;
  transition: 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
  display: block;
  cursor: pointer;
  z-index: 2;
}
.readon.submit:hover {
  color: #fff;
  opacity: 0.99;
}
.readon.submit.blue-small {
  background-color: #00a3e8;
  padding: 17px 30px;
  font-size: 16px;
  border-radius: 0;
}
.readon.submit.orange-submit {
  background-color: #00a3e8;
  font-size: 16px;
}
.readon.submit.paste-btn {
  color: #fff;
  background-color: #4caf50;
  margin: 0;
  padding: 18px 35px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 4px;
}
.readon.post {
  padding: 14px 25px;
  border-radius: 0;
  line-height: normal;
  font-size: 14px;
  font-weight: 500;
  background: #00a3e8;
  border: none;
}
.readon.price {
  padding: 8px 30px;
  color: #0a0a0a;
  background-color: #fff;
  border: 1px solid #0a0a0a;
  border-radius: 0;
}
.readon.price.price2,
.readon.price:hover {
  border: 1px solid #00a3e8;
}
.readon.apply,
.readon.price:hover {
  color: #fff;
  background-color: #00a3e8;
}
.readon.price.price2:hover {
  border-width: 1px;
  border-color: #df1313;
  background: #df1313;
}
.readon.price.big {
  font-size: 20px;
  font-weight: 500;
}
.readon.apply {
  padding: 5px 15px;
  font-size: 15px;
  font-weight: 600;
}
.add-btn,
.submit-btn {
  font-weight: 500;
  font-size: 16px;
}
.readon.view-more {
  padding: 7px 25px;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  color: #111;
  background-color: #fff;
  border-radius: 0;
}
.readon.view-more.small {
  padding: 7px 25px;
  border-radius: 4px;
}
.readon2 {
  padding: 12px 35px 10px;
  border-radius: 30px;
  color: #fff !important;
  display: inline-flex;
  align-items: center;
  background: #01a3e8;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
  outline: 0;
  transition: 0.3s !important;
}
.readon2 .btn-arrow {
  width: 20px;
  height: 16px;
  overflow: hidden;
  margin-left: 11px;
}
.readon2 .btn-arrow:after,
.readon2 .btn-arrow:before {
  position: absolute;
  content: "\f178";
  font-family: FontAwesome;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
  transition: 0.3s;
}
.readon2 .btn-arrow:before,
.readon2:hover div:after,
.rs-services.style1
  .service-wrap
  .service-grid
  .btn-wrap
  .readmore
  .btn-arrow:before,
.rs-services.style1
  .service-wrap
  .service-grid
  .btn-wrap
  .readmore:hover
  div:after {
  right: 1px;
}
.readon2 .btn-arrow:after,
.rs-services.style1
  .service-wrap
  .service-grid
  .btn-wrap
  .readmore
  .btn-arrow:after {
  right: 22px;
}
.readon2:hover {
  color: #fff;
  background: #043d72;
}
.home-6
  .full-width-header
  .right_menu_togle
  .canvas-contact
  .address-area
  .address-list
  .info-icon
  i::before,
.home-6 .full-width-header .rs-header.style2 .expand-btn-inner ul li > a:hover,
.home-6
  .rs-project.style1
  .project-item
  .project-content
  .project-inner
  .title
  a:hover,
.home-6 a:hover,
.readon2.hover-light:hover .btn-arrow:after,
.readon2.hover-light:hover .btn-arrow:before,
.readon2:hover div:after,
.readon2:hover div:before,
.rs-footer .bg-wrap .footer-content .about-widget a:hover,
.rs-services.style1 .service-wrap .service-grid .btn-wrap .readmore:hover,
.rs-services.style1
  .service-wrap
  .service-grid
  .btn-wrap
  .readmore:hover
  div:after,
.rs-services.style1
  .service-wrap
  .service-grid
  .btn-wrap
  .readmore:hover
  div:before,
.rs-services.style1 .service-wrap .service-grid .title a:hover {
  color: #e94d65;
}
.readon2:hover div:before,
.rs-services.style1
  .service-wrap
  .service-grid
  .btn-wrap
  .readmore:hover
  div:before {
  right: -20px;
}
.readon2.hover-light:hover {
  background: #fff;
  color: #e94d65 !important;
}
.left-line-v {
  padding-left: 20px;
}
.left-line-v .draw-line.start-draw {
  position: absolute;
  content: "";
  left: 0;
  top: 3px;
  width: 4px;
  height: 0;
  background: #e94d65;
  animation: 1.5s forwards start-draw;
}
.blog-button a {
  text-align: center;
  color: #00a3e8;
  padding-right: 25px;
  z-index: 1;
  font-weight: 700;
  font-size: 15px;
}
body .search-modal,
body.modal-open {
  padding: 0 !important;
}
.blog-button a:after {
  content: "\f114";
  position: absolute;
  font-family: flaticon;
  font-size: 15px;
  top: 1px;
  right: 0;
  opacity: 1;
  transition: 0.3s;
}
.blog-button a:hover:after {
  right: -5px;
  opacity: 1;
}
.blog-button.services a {
  font-size: 16px;
  font-weight: 600;
  color: #00a3e8;
  padding-right: 12px;
}
.blog-button.services a:after {
  content: "\f113";
  position: absolute;
  font-family: flaticon;
  font-size: 10px;
  top: 1px;
  right: 0;
  opacity: 1;
  transition: 0.3s;
  color: #00a3e8;
}
.blog-button.services a:hover:after {
  right: -2px;
  opacity: 1;
  transform: unset;
}
.add-btn {
  cursor: pointer;
  transition: 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
  padding: 10px 40px;
  border-radius: 3px;
  background: #00a3e8;
  color: #fff;
  border: none;
}
.add-btn:hover,
.rs-breadcrumbs .breadcrumbs-inner ul li a:hover {
  opacity: 0.7;
}
.submit-btn {
  box-shadow: 0 28px 50px 0 rgba(0, 0, 0, 0.05);
  outline: 0;
  border: none;
  padding: 18px;
  border-radius: 3px;
  text-transform: capitalize;
  color: #fff;
  background-color: #4caf50;
  transition: 0.3s;
  overflow: hidden;
  width: 100%;
  cursor: pointer;
}
.rs-appointment.style1
  .appoint-schedule
  .contact-wrap
  input[type="submit"]:hover,
.rs-contact.home3-contact-style .contact-wrap input[type="submit"]:hover,
.rs-contact.main-home .contact-wrap input[type="submit"]:hover,
.rs-contact.main-home.home5-contact-style input[type="submit"]:hover,
.rs-footer.style1 .footer-top p input[type="submit"]:hover,
.rs-services-single .social-icons li a i:hover,
.submit-btn:hover {
  opacity: 0.9;
}
.margin-remove {
  margin: 0 !important;
}
.no-gutter {
  margin-left: 0;
  margin-right: 0;
}
.sec-title .sub-text,
.sub-text {
  font-size: 15px;
  line-height: 28px;
  font-weight: 600;
  margin: 0 0 10px;
}
.no-gutter [class*="col-"] {
  padding-left: 0;
  padding-right: 0;
}
.container-fluid {
  padding-left: 18px;
  padding-right: 18px;
}
.container-fluid2 {
  padding-left: 15px;
  padding-right: 15px;
}
.pt-relative {
  position: relative !important;
}
.sub-text {
  color: #00a3e8;
  text-transform: uppercase;
}
.desc {
  font-weight: 500;
}
.sec-title .sub-text {
  color: #191821;
  text-transform: capitalize;
  display: block;
}
.sec-title .sub-text.big {
  font-size: 17px;
  font-weight: 500;
  color: #333;
}
.sec-title .sub-text.small {
  font-size: 15px;
  color: #00a3e8;
  text-transform: uppercase;
}
.sec-title .title {
  font-size: 36px;
  line-height: 46px;
  font-weight: 700;
  color: #0a0a0a;
  margin: 0;
  padding-top: 28px;
}
.sec-title3 .sub-text,
.sec-title3 .sub-title {
  color: #00a3e8;
  margin: 0 0 10px;
  text-transform: uppercase;
}
.sec-title .title span.new-text,
.sec-title2 .title span.new-next {
  font-style: unset;
}
.sec-title .title:after {
  content: "";
  position: absolute;
  border: 0;
  width: 65px;
  height: 5px;
  background: #00a3e8;
  z-index: 1;
  margin-left: 0;
  left: 0;
  top: 0;
}
.sec-title3 .sub-text:after,
.sec-title3 .sub-text:before {
  position: absolute;
  top: 12px;
  background-color: #00a3e8;
  transition: 0.3s;
  z-index: 0;
}
.sec-title .title.title2:after {
  left: 50%;
  transform: translateX(-50%);
}
.sec-title .title.title2.contact {
  max-width: 530px;
  margin: 0 auto;
  color: #fff;
}
.rs-partner .owl-carousel,
.sec-title .title.title3 {
  padding-top: 0;
}
.blog-details-desc
  .article-leave-comment
  .form-cookies-consent
  [type="checkbox"]:checked,
.blog-details-desc
  .article-leave-comment
  .form-cookies-consent
  [type="checkbox"]:not(:checked),
.full-width-header .mobile-menu,
.full-width-header .mobile-navbar-menu .submenu-button.submenu-opened:before,
.full-width-header
  .rs-header
  .expand-btn-inner
  ul
  .humburger
  .nav-expander
  .bar,
.full-width-header .rs-header .menu-area .logo-area .dark,
.full-width-header .rs-header .menu-area .logo-area a .sticky-logo,
.full-width-header
  .rs-header
  .menu-area
  .main-menu
  .rs-menu
  ul.sub-menu
  li.menu-item-has-children
  a:before,
.full-width-header .rs-header .menu-area.sticky .logo-area a .normal-logo,
.rs-footer.style1 .footer-bottom .copy-right-menu li:first-child:before,
.sec-title .title.title3:after {
  display: none;
}
.sec-title .title.title3.small {
  font-size: 28px;
}
.sec-title .title.title4 {
  font-size: 28px;
  line-height: 36px;
}
.sec-title .title-inner .title-small {
  font-size: 17px;
  line-height: 26px;
  font-weight: 600;
  margin: 0 0 6px;
  color: #0a0a0a;
}
.sec-title2 .title {
  font-size: 36px;
}
.sec-title2 .first-half {
  max-width: 50%;
  flex: 0 0 50%;
}
.sec-title2 .last-half {
  max-width: 50%;
  flex: 0 0 50%;
  font-weight: 500;
}
.sec-title3 .sub-title {
  font-size: 15px;
  font-weight: 800;
  display: block;
}
.home-paste-color
  .full-width-header
  .right_menu_togle
  .canvas-contact
  .address-area
  .address-list
  .info-content
  em
  a:hover,
.home-paste-color
  .full-width-header
  .right_menu_togle
  .canvas-contact
  .address-area
  .address-list
  .info-icon
  i::before,
.rs-footer.style1.footer-home5-style .footer-bottom .copy-right-menu li a:hover,
.rs-footer.style1.footer-home5-style
  .footer-top
  .address-widget
  li
  .desc
  a:hover,
.rs-footer.style1.footer-home5-style .footer-top .address-widget li i:before,
.rs-footer.style1.footer-home5-style .footer-top .site-map li a:hover,
.rs-services.style7 .services-item .services-content .title:hover,
.sec-title3 .sub-title.paste-color {
  color: #4caf50;
}
.sec-title3 .sub-text {
  font-size: 15px;
  font-weight: 500;
  line-height: 28px;
  display: block;
  padding-left: 107px;
}
.sec-title3 .sub-text:before {
  content: "";
  left: 0;
  height: 4px;
  width: 12px;
  border-radius: 2px;
}
.sec-title3 .sub-text:after {
  content: "";
  border: 0;
  height: 4px;
  width: 55px;
  margin-left: 0;
  left: 24px;
  border-radius: 2px;
}
.sec-title3 .heading-border-line:after,
.sec-title3 .heading-border-line:before {
  height: 4px;
  position: absolute;
  bottom: -4px;
  border-radius: 2px;
  background: #00a3e8;
  content: "";
}
.para21 {
  font-size: 16px;
}
.white-color {
  color: #fff !important;
}
.sec-title3 .title {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  color: #0a0a0a;
  margin-bottom: 13px;
}
.sec-title3 .heading-border-line:before {
  width: 12px;
  left: 50%;
  margin-left: -35px;
  transform: translateX(-50%);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  z-index: 1;
}
.sec-title3 .heading-border-line:after {
  width: 65px;
  right: 0;
  left: 50%;
  margin-left: -20px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  z-index: 0;
}
.sec-title3 .heading-border-line.left-style {
  position: relative;
  margin-left: 44px;
}
.sec-title3 .heading-border-line.left-style:after,
.sec-title3 .heading-border-line.left-style:before {
  left: 0;
}
.sec-title3:hover .sub-text:before {
  width: 65px;
  background: #00a3e8;
}
.sec-title3:hover .sub-text:after {
  margin-left: 46px;
  width: 12px;
  background: #00a3e8;
}
.sec-title3:hover .heading-border-line:before {
  margin-left: -5px;
  width: 65px;
  background: #00a3e8;
}
.sec-title3:hover .heading-border-line:after {
  width: 12px;
  margin-left: 35px;
  background: #00a3e8;
}
.sec-title4 .sub-title {
  color: #e94d65;
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 14px;
}
.rs-services.style1 .service-wrap .service-grid .title a,
.sec-title4 .title {
  color: #043d72;
}
.full-width-header .rs-header .menu-area {
  transition: 0.3s;
  background: 0 0;
  border-bottom: 2px solid #f1f0f052;
}
.full-width-header .rs-header .menu-area .row-table {
  display: flex;
  width: 100%;
  position: relative;
  align-items: center;
  justify-content: space-between;
}
.full-width-header .rs-header .expand-btn-inner,
.full-width-header .rs-header .menu-area .row-table .col-cell {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.full-width-header .rs-header .menu-area .row-table .col-cell.header-logo {
  display: flex;
  flex: 1;
}
.full-width-header .rs-header .menu-area .logo-area img {
  transition: 0.4s;
  -webkit-transition: 0.4s;
  max-height: 60px;
}
.full-width-header .rs-header .menu-area .logo-area .light {
  display: inherit;
}
.full-width-header .rs-header .menu-area .rs-menu-area {
  display: flex;
  align-items: center;
  margin-right: 0;
}
.full-width-header .rs-header .menu-area .main-menu {
  position: unset;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li {
  display: inline-block;
  margin-right: 5px;
  padding: 0;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.nav-menu li a {
  transition: 0.3s;
  font-size: 15px;
  font-weight: 600;
  margin-right: 30px;
  height: 95px;
  line-height: 95px;
  padding: 0;
  color: #fff;
  position: relative;
  font-family: Montserrat;
}
.full-width-header
  .rs-header
  .menu-area
  .main-menu
  .rs-menu
  ul.nav-menu
  li.menu-item-has-children
  > a {
  padding-right: 17px;
}
.full-width-header
  .rs-header
  .menu-area
  .main-menu
  .rs-menu
  ul.nav-menu
  li.menu-item-has-children
  > a:before {
  content: "+";
  position: absolute;
  left: auto;
  right: 0;
  top: 50%;
  text-align: center;
  z-index: 9999;
  font-size: 16px;
  display: block;
  cursor: pointer;
  transform: translateY(-50%);
  font-weight: 400;
}
.full-width-header
  .rs-header
  .menu-area
  .main-menu
  .rs-menu
  ul.nav-menu
  li.menu-item-has-children:hover
  > a:before {
  content: "-";
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.sub-menu {
  background: #fff;
  margin: 0;
  padding: 20px 0;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.sub-menu li {
  margin: 0 !important;
  border: none;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.sub-menu li a {
  padding: 10px 30px;
  margin: 0;
  font-family: Montserrat;
  font-size: 14px;
  color: #101010;
  font-weight: 500;
  height: unset;
  line-height: 25px;
  text-transform: capitalize;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul.sub-menu.right {
  left: auto;
  right: 100%;
}
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul li a:hover,
.full-width-header .rs-header .menu-area .main-menu .rs-menu ul li.active a,
.full-width-header
  .rs-header
  .menu-area
  .main-menu
  .rs-menu
  ul
  li.current-menu-item
  > a,
.full-width-header
  .rs-header
  .menu-area
  .main-menu
  .rs-menu
  ul
  li.current-menu-item
  > a:before {
  color: #00a3e8 !important;
}
.full-width-header .rs-header .menu-area.sticky .logo-area a .sticky-logo {
  display: block;
  max-height: 50px;
}
.full-width-header .rs-header .menu-area.sticky .expand-btn-inner ul .rsphone a,
.full-width-header
  .rs-header
  .menu-area.sticky
  .expand-btn-inner
  ul
  .search-parent
  a
  i,
.full-width-header
  .rs-header
  .menu-area.sticky
  .main-menu
  .rs-menu
  ul.nav-menu
  > li
  > a,
.rs-services.style8
  .flip-box-inner
  .flip-box-wrap
  .front-part
  .front-content-part
  .front-title-part
  .title
  a {
  color: #101010;
}
.about-box-inner
  .flip-box-wrap
  .front-part
  .front-content-part
  .front-title-part
  .title
  a,
.full-width-header
  .rs-header
  .menu-area.sticky
  .expand-btn-inner
  ul
  .icon-bar
  .cart-icon
  i,
.rs-contact.main-home
  .contact-icons-style.box-address
  .contact-item
  .content-text
  .title
  a,
.rs-services.home-style2 .services-item .services-text .title a,
.rs-services.main-home.services-style1 .services-item .services-text .title a,
.rs-services.style2 .service-wrap .content-part .title a,
.rs-services.style3 .services-item .services-content .services-title a,
.rs-services.style4 .services-item .services-content .title a,
.rs-services.style5
  .flip-box-inner
  .flip-box-wrap
  .front-part
  .front-content-part
  .front-title-part
  .title
  a,
.rs-services.style6
  .services-box-area
  .services-item
  .services-content
  .title
  a,
.rs-shop-part .product-list .content-desc .loop-product-title a,
.rs-team.style2 .team-item .team-content .social-icon li a i:hover,
.rs-team.style2 .team-item .team-content .team-info .name a,
.rs-team.style3 .team-item .team-content .team-info .name a,
.rs-team.style4.home-4-team-style .team-item-wrap .team-content .person-name a {
  color: #0a0a0a;
}
.full-width-header
  .rs-header
  .menu-area.sticky
  .expand-btn-inner
  ul
  .nav-link
  .nav-expander
  .bar
  span {
  background: #101010;
}
.full-width-header .rs-header .expand-btn-inner ul,
.rs-services.style7 .services-item,
.rs-testimonial.main-home .testi-item .testi-content {
  display: flex;
  align-items: center;
}
.full-width-header .rs-header .expand-btn-inner ul li,
.rs-team.style3 .team-item .team-content .social-icon li {
  display: inline-block;
  margin-right: 22px;
}
.full-width-header .rs-header .expand-btn-inner ul li > a {
  color: #fff;
  display: inline-block;
  cursor: pointer;
}
.full-width-header .rs-header .expand-btn-inner ul li > a i:before {
  font-size: 18px;
  font-weight: 600;
}
.full-width-header
  .rs-header
  .expand-btn-inner
  ul
  li
  > a
  i.flaticon-shopping-bag-1:before,
.home-6 .desc {
  font-weight: 400;
}
.blog-details-desc .article-content .entry-list li:last-child,
.blog-details-desc .article-leave-comment .form-cookies-consent p:last-child,
.blog-details-desc .article-share .share-social li:last-child,
.full-width-header .rs-header .expand-btn-inner ul li:last-child,
.rs-single-shop .tab-area ul.nav-tabs li:last-child,
.rs-team-Single
  .btm-info-team
  .con-info
  .ps-informations
  .social-info
  li:last-child,
.rs-team.style2 .team-item .team-content .social-icon li:last-child,
.rs-team.style3 .team-item .team-content .social-icon li:last-child,
.rs-team.style4 .team-item-wrap .team-content .team-social li:last-child {
  margin-right: 0;
}
.full-width-header
  .rs-header
  .expand-btn-inner
  ul
  .humburger
  .nav-expander
  .bar {
  padding: 16px 0 16px 13px;
  background: 0 0;
  max-width: 50px;
  flex-wrap: wrap;
  border-radius: 2px;
  margin-right: -4px;
}
.full-width-header
  .rs-header
  .expand-btn-inner
  ul
  .humburger
  .nav-expander
  .bar
  span {
  height: 5px;
  width: 5px;
  display: block;
  background: #00a3e8;
  border-radius: 50%;
  transition: 0.3s;
  list-style: none;
  margin: 2px 3px;
}
.full-width-header .rs-header.header-transparent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
}
.full-width-header .mobile-menu .mobile-logo {
  float: left;
}
.full-width-header .mobile-menu .mobile-logo img {
  max-height: 30px;
}
.full-width-header .mobile-menu .rs-menu-toggle {
  color: #fff;
  float: right;
}
.full-width-header .mobile-menu .rs-menu-toggle.secondary {
  color: #191821;
}
.full-width-header .mobile-menu .rs-menu-toggle .nav-dots {
  position: absolute;
  right: 0;
  width: 25px;
  height: 2px;
  background: #fff;
  z-index: 11;
  top: 23px;
}
.full-width-header .mobile-menu .rs-menu-toggle .dot1,
.full-width-header .mobile-menu .rs-menu-toggle .dot3 {
  width: 15px;
}
.full-width-header .mobile-menu .rs-menu-toggle .dot2 {
  top: 28px;
}
.full-width-header .mobile-menu .rs-menu-toggle .dot3 {
  top: 34px;
}
.full-width-header .mobile-menu .rs-menu-toggle .dot4 {
  top: 40px;
}
.full-width-header .right_menu_togle {
  background: #000;
  padding: 46px 40px 50px 50px;
  font-size: 1.15em;
  max-width: 480px;
  width: 100%;
  right: -500px;
  z-index: 999999;
  transition: 0.3s;
  position: fixed;
  overflow-y: auto;
}
.full-width-header .right_menu_togle .close-btn {
  overflow: visible;
}
.full-width-header .right_menu_togle .close-btn .nav-close {
  position: absolute;
  top: 25px;
  display: block;
  z-index: 99;
  right: 35px;
  cursor: pointer;
  transition: 0.3s;
}
.full-width-header .right_menu_togle .close-btn .nav-close .line {
  width: 40px;
  height: 40px;
  line-height: 40px;
}
.full-width-header .right_menu_togle .close-btn .nav-close .line span {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  width: 2px !important;
  height: 25px !important;
  background: #fff;
  border: none !important;
  transition: 0.3s;
}
.full-width-header .right_menu_togle .close-btn .nav-close .line span.line2 {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.full-width-header .right_menu_togle .canvas-logo {
  padding-left: 0;
  padding-bottom: 25px;
}
.full-width-header .right_menu_togle .canvas-logo img {
  max-height: 28px;
}
.full-width-header .right_menu_togle .offcanvas-text p {
  font-size: 16px;
  line-height: 30px;
  color: #fff;
  margin: 5px 0 45px;
}
.full-width-header .right_menu_togle .canvas-contact {
  padding: 30px 0 0;
}
.full-width-header
  .right_menu_togle
  .canvas-contact
  .address-area
  .address-list {
  display: flex;
  margin-top: 18px;
}
.full-width-header
  .right_menu_togle
  .canvas-contact
  .address-area
  .address-list
  .info-icon
  i:before {
  margin: 0 20px 0 0;
  font-size: 25px;
  color: #00a3e8;
}
.full-width-header
  .right_menu_togle
  .canvas-contact
  .address-area
  .address-list
  .info-content
  .title {
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 5px;
}
.full-width-header
  .right_menu_togle
  .canvas-contact
  .address-area
  .address-list
  .info-content
  em {
  display: block;
  font-style: normal;
  line-height: 22px;
  font-size: 15px;
  color: #fff;
}
.full-width-header .right_menu_togle .canvas-contact .social {
  margin: 40px 0 0;
}
.full-width-header .right_menu_togle .canvas-contact .social li a i {
  font-size: 15px;
  color: #fff;
  margin: 0 8px 0 0;
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 0.1);
  width: 35px;
  height: 35px;
  line-height: 35px;
  border-radius: 0;
}
.full-width-header .mobile-navbar-menu .sub-menu {
  display: none;
  position: relative;
  left: 0;
  width: 100%;
  margin: 0;
  text-align: left;
}
.c.mobile-navbar-menu .nav-menu li a {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  display: block;
  color: #fff;
  padding: 10px 0;
  line-height: 30px;
  width: 100%;
}
.blog-card:hover .blog-content .blog-btn,
.full-width-header .mobile-navbar-menu .nav-menu li li a,
.rs-testimonial.home5-testi-style
  .testi-box-style
  .testi-item
  .testi-content
  .testi-information,
.rs-testimonial.main-home .testi-item .testi-content .testi-information,
.single-blog-card:hover .blog-content .blog-btn {
  padding-left: 25px;
}
.full-width-header .mobile-navbar-menu .nav-menu li li li a {
  padding-left: 50px;
}
.full-width-header .mobile-navbar-menu span.submenu-button {
  position: absolute;
  z-index: 99;
  right: 0;
  top: 5px;
  display: block;
  height: 40px;
  width: 40px;
  cursor: pointer;
}
.full-width-header .mobile-navbar-menu span.submenu-button:after,
.full-width-header .mobile-navbar-menu span.submenu-button:before {
  position: absolute;
  top: 24px;
  height: 1px;
  display: block;
  background: #fff;
  z-index: 2;
  content: "";
  right: 12px;
  width: 12px;
}
.full-width-header .mobile-navbar-menu span.submenu-button:before {
  width: 1px;
  height: 12px;
  right: 17px;
  top: 18px;
}
.full-width-header .mobile-navbar-menu span.submenu-button em {
  display: block;
  position: absolute;
  right: 0;
  border-radius: 4px;
  top: 50%;
  transform: translateY(-50%);
}
.full-width-header .mobile-navbar-menu .hash-has-sub span.submenu-button {
  width: 100%;
  cursor: pointer;
  top: 0;
  height: 50px;
}
.full-width-header
  .mobile-navbar-menu
  ul.nav-menu
  > li:first-child
  > span.submenu-button:after {
  top: 19px;
}
.full-width-header
  .mobile-navbar-menu
  ul.nav-menu
  > li:first-child
  > span.submenu-button:before {
  top: 13px;
}
body .modal.show .modal-dialog {
  width: 100%;
  max-width: 900px;
}
.accordion label:focus,
.accordion label:hover,
.loginbtn,
body .search-modal {
  background: #000;
}
body .search-modal .modal-content {
  position: initial;
  border: 0;
  background: unset;
}
body .search-modal .search-block form input {
  background: 0 0;
  border: none;
  color: #fff;
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
  outline: 0;
  font-size: 35px;
}
body .search-modal .search-block form button {
  background: #000;
  border: none;
  padding: 5px 20px;
  color: #fff;
  border-radius: 0 5px 5px 0;
  font-size: 16px;
  position: absolute;
  content: "";
  right: 0;
  top: 11px;
}
body .search-modal .search-block form button i:before {
  color: #fff;
  font-size: 35px;
  font-weight: 600;
}
body .search-modal .search-block ::-webkit-input-placeholder {
  color: #fff;
  opacity: 1;
}
body .search-modal .search-block ::-moz-placeholder {
  color: #fff;
  opacity: 0.3;
}
body .search-modal .search-block :-ms-input-placeholder {
  color: #fff;
  opacity: 1;
}
body .search-modal .search-block :-moz-placeholder {
  color: #fff;
  opacity: 1;
}
body .search-modal .close {
  position: fixed;
  right: 50px;
  top: 50px;
  background: #fff;
  color: #0a0a0a;
  width: 55px;
  height: 55px;
  line-height: 58px;
  text-align: center;
  cursor: pointer;
  border-radius: 50%;
  transition: 0.5s;
  opacity: unset;
  box-shadow: unset;
  border: none;
}
body .search-modal .close span:before {
  font-size: 14px;
  position: relative;
  top: -2px;
  left: 0;
  font-weight: 700;
}
body .search-modal .close:hover {
  opacity: 0.8 !important;
}
.menu-sticky {
  background: #fff;
  margin: 0;
  z-index: 999;
  width: 100%;
  top: 0;
  position: relative;
}
@-webkit-keyframes sticky-animation {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@keyframes sticky-animation {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.rs-appointment.style1 .border-section {
  border-style: solid;
  border-width: 2px 0 0;
  border-color: #02010108;
  padding: 50px 0 100px;
}
.rs-appointment.style1 .appoint-schedule {
  box-shadow: 0 6px 25px rgb(12 89 219 / 9%);
  padding: 60px 60px 83px;
}
.rs-appointment.style1 .appoint-schedule .contact-wrap .from-control {
  padding: 10px;
  color: #000;
  border: 1px solid #c1c0c0;
  background-color: #fff;
  width: 100%;
  max-width: 100%;
  box-shadow: 0 6px 25px rgb(12 89 219 / 9%);
  opacity: 1;
  border-radius: 20px;
}
.rs-appointment.style1
  .appoint-schedule
  .contact-wrap
  .wpcf7-form-control:not(.wpcf7-submit) {
  width: 100%;
  padding: 10px;
  border-radius: 20px;
  color: #000;
  border: 1px solid #c1c0c0;
  background-color: #fff;
  box-shadow: 0 6px 25px rgb(12 89 219 / 9%);
}
.rs-appointment.style1 .appoint-schedule .contact-wrap textarea,
.rs-contact.contact-style2 .contact-wrap textarea,
.rs-contact.home3-contact-style .contact-wrap textarea,
.rs-contact.main-home .contact-wrap textarea,
.rs-contact.main-home.office-modify1 .contact-section .contact-wrap textarea {
  height: 120px;
}
.rs-appointment.style1
  .appoint-schedule
  .contact-wrap
  ::-webkit-input-placeholder {
  color: #000;
  opacity: 1;
}
.rs-appointment.style1 .appoint-schedule .contact-wrap ::-moz-placeholder {
  color: #000;
  opacity: 1;
}
.rs-appointment.style1 .appoint-schedule .contact-wrap :-ms-input-placeholder {
  color: #000;
  opacity: 1;
}
.rs-appointment.style1 .appoint-schedule .contact-wrap :-moz-placeholder {
  color: #000;
  opacity: 1;
}
.rs-appointment.style1.apply-career
  .appoint-schedule
  .contact-wrap
  .form-group
  label {
  color: #000;
  font-weight: 600;
  display: block;
  margin-bottom: 8px;
}
.rs-appointment.style1.apply-career
  .appoint-schedule
  .contact-wrap
  .form-group
  .form-control-mod {
  width: 100%;
  padding: 10px;
  color: #000;
  border: 1px solid #c1c0c0;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 6px 25px rgb(12 89 219 / 9%);
}
.rs-breadcrumbs {
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center center !important;
}
.rs-breadcrumbs .breadcrumbs-inner {
  padding: 100px 0;
}
.rs-breadcrumbs .breadcrumbs-inner .page-title {
  font-size: 48px;
  line-height: 56px;
  font-weight: 700;
  margin-bottom: 10px;
  color: #fff;
  position: relative;
  z-index: 1;
  padding-top: 20px;
}
.rs-breadcrumbs .breadcrumbs-inner .page-title:after {
  content: "";
  height: 5px;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  z-index: -1;
  background: #00a3e8;
  width: 65px;
  margin: 0 auto;
}
.rs-breadcrumbs .breadcrumbs-inner .page-title .watermark {
  position: absolute;
  font-size: 70px;
  text-transform: uppercase;
  font-weight: 900;
  font-family: Montserrat, sans-serif;
  opacity: 0.05;
  width: 100%;
  left: 0;
  color: inherit;
  white-space: nowrap;
  vertical-align: middle;
}
.rs-breadcrumbs .breadcrumbs-inner .sub-text {
  font-size: 18px;
  line-height: 30px;
  font-weight: 500;
  color: #fff;
  text-transform: unset;
  display: inline-block;
  margin-top: 10px;
  padding-right: 290px;
}
.navtrh .dropdown-menu,
.rs-breadcrumbs .breadcrumbs-inner ul,
.rs-slider.slider3 .nivo-caption {
  padding: 0;
}
.rs-breadcrumbs .breadcrumbs-inner ul li {
  color: #fff;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
}
.rs-breadcrumbs .breadcrumbs-inner ul li a {
  position: relative;
  padding-right: 22px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  color: #fff;
}
.rs-breadcrumbs .breadcrumbs-inner ul li a:before {
  background-color: #fff;
  content: "";
  height: 15px;
  width: 1px;
  position: absolute;
  right: 9px;
  top: 2px;
  transform: rotate(26deg);
}
.rs-breadcrumbs.img1 {
  background: url(../../public/images/inr_1.jpg);

}
.rs-slider {
  position: relative;
  overflow: hidden;
  text-align: left;
}
#pre-load,
#scrollUp,
.social {
  position: fixed;
}
.rs-slider.slider3 .desc {
  font-size: 17px;
  color: #0a0a0a;
  max-width: 500px;
  margin: 25px 0 45px;
}
.rs-slider.slider3 .slider-bottom .consultant {
  display: inline-block !important;
}
.rs-slider.slider3 .sl-subtitle {
  -webkit-animation: 1.7s ease-in-out fadeInDown;
  animation: 1.7s ease-in-out fadeInDown;
}
.rs-slider.slider3 .sl-title {
  -webkit-animation: 1.2s ease-in-out fadeInUp;
  animation: 1.2s ease-in-out fadeInUp;
}
.rs-slider.slider3 .slider-bottom {
  display: block;
  overflow: hidden;
  -webkit-animation: 1.8s ease-in-out fadeInUp;
  animation: 1.8s ease-in-out fadeInUp;
}
.rs-slider.slider3 .content-part {
  position: absolute;
  content: "";
  top: 50%;
  left: 39%;
  transform: translate(-50%, -50%);
}
.rs-slider.slider3 .content-part .sl-subtitle {
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 12px;
  color: #00a3e8;
}
.rs-slider.slider3 .content-part .sl-title {
  font-size: 80px;
  line-height: 1.1;
  font-weight: 700;
  margin-bottom: 36px;
}
.rs-slider.slider3 .content-part .slider-bottom .readon {
  display: block !important;
  font-size: 15px;
  font-weight: 500;
  padding: 15px 32px;
}
.rs-slider.slider3 .content-part .slider-bottom ul li {
  display: inline-block;
  margin-right: 15px;
}
.rs-slider.slider3 .nivo-directionNav > a {
  background: rgba(0, 0, 0, 0.2);
}
.rs-slider .sl-wrap {
  padding: 50px 0;
}
.rs-slider .nivo-directionNav > a {
  font-size: 0;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  border: none;
  background: #ebebeb;
  -webkit-transition: 0.4s ease-out;
  transition: 0.4s ease-out;
  opacity: 1;
}
.rs-slider .nivo-directionNav > a.nivo-nextNav,
.rs-slider .nivo-directionNav > a.nivo-prevNav {
  left: 5px;
  background: #00a3e8;
}
.rs-slider .nivo-directionNav > a.nivo-prevNav {
  top: 44.4%;
}
.rs-slider .nivo-directionNav > a.nivo-prevNav:before {
  content: "\f104";
}
.rs-slider .nivo-directionNav > a.nivo-nextNav {
  top: 53%;
}
.rs-slider .nivo-directionNav > a.nivo-nextNav:before {
  content: "\f105";
}
.rs-slider .nivo-directionNav > a:before {
  font-family: FontAwesome;
  position: absolute;
  font-size: 28px;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
  transition: 0.3s;
  color: #00a3e8;
}
.rs-slider .nivo-directionNav > a:hover {
  opacity: 95;
}
.rs-slider .nivo-controlNav {
  position: absolute;
  bottom: 50px;
  z-index: 11;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  display: none !important;
}
.rs-slider .nivo-controlNav .nivo-control {
  width: 26px;
  height: 7px;
  border: 1px solid #fff;
  font-size: 0px;
  display: inline-block;
  margin-right: 7px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.rs-slider .nivo-controlNav .nivo-control.active {
  background: #00a3e8;
  border-color: #00a3e8;
}
.rs-slider.rs-slider-style3.slider3 .content-part .sl-subtitle {
  font-size: 40px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 15px;
  color: #00a3e8;
}
.rs-slider.rs-slider-style3.slider3 .content-part .sl-title {
  font-size: 18px;
  line-height: 30px;
  font-weight: 500;
  margin-bottom: 36px;
  color: #fff;
}
.rs-slider.rs-slider-style3 .nivo-directionNav > a {
  border-radius: 50%;
}
.rs-slider.rs-slider-style3 .nivo-directionNav > a.nivo-nextNav,
.rs-slider.rs-slider-style3 .nivo-directionNav > a.nivo-prevNav {
  left: 30px;
  background: #00a3e8;
}
.rs-slider.rs-slider-style3 .nivo-directionNav > a.nivo-prevNav {
  top: 50%;
  transform: translateY(-50%);
}
.rs-slider.rs-slider-style3 .nivo-directionNav > a.nivo-prevNav:before {
  content: "\f104";
  color: #fff;
}
.rs-slider.rs-slider-style3 .nivo-directionNav > a.nivo-nextNav {
  top: 50%;
  transform: translateY(-50%);
  right: 30px;
  left: unset;
}
.rs-slider.rs-slider-style3 .nivo-directionNav > a.nivo-nextNav:before {
  content: "\f105";
  color: #fff;
}
.rs-iconbox-area .box-inner .box-item {
  background: #fff;
  padding: 50px 12px 40px;
  box-shadow: 5px 8px 20px 6px #eee;
  text-align: center;
  margin: 0 3px;
}
.mb-30,
.rs-iconbox-area .box-inner .box-item .icon-box,
.rs-single-shop .single-price-info form,
.rs-testimonial.style1 .item .testi-img,
.technology-detail-inner p:last-of-type,
.technology-item .bodytech p {
  margin-bottom: 30px;
}
.resume-item .body,
.rs-services.home-style2 .services-item,
.rs-services.main-home .services-item {
  display: flex;
}
.rs-services.home-style2 .services-item .services-icon img,
.rs-services.main-home .services-item .services-icon img {
  height: 70px;
  width: 70px;
  max-width: unset;
}
.rs-services.main-home .services-item .services-text {
  padding: 0 0 0 30px;
}
.rs-services.main-home .services-item .services-text .title {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 13px;
}
.rs-services.main-home .services-item .services-text .services-txt {
  color: #f5f5f5;
  margin: 0;
}
.rs-services.main-home.services-style1
  .services-item
  .services-text
  .services-txt {
  color: #454545;
  margin-bottom: 26px;
}
.rs-services.main-home.services-style1
  .services-item
  .services-text
  .serial-number {
  position: absolute;
  left: -6px;
  top: 44px;
  color: #00000008;
  font-size: 70px;
  font-weight: 600;
}
.rs-services.main-home.services-style1 .services-item:after {
  display: block;
  width: 47px;
  left: 124px;
  background: #c12a2a;
  content: "";
  height: 4px;
  position: absolute;
  transition: 0.3s;
  bottom: 0;
  z-index: 9;
  transform: translateX(-50%);
}
.rs-services.chooseus-style .services-item {
  display: flex;
  padding: 25px;
  background-color: #15181d;
  margin: 0 -4px 0 0;
}
.rs-services.chooseus-style .services-item .services-icon img {
  height: 65px;
  width: 65px;
  max-width: unset;
  padding: 5px 5px 0 0;
}
.rs-services.chooseus-style .services-item .services-text {
  padding: 15px 0 0 15px;
}
.rs-services.chooseus-style .services-item .services-text .title {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  margin: 0 0 10px;
}
.rs-services.chooseus-style .services-item .services-text .services-txt {
  margin: 0;
  color: #f4f4f4;
}
.rs-services.chooseus-style.about-style .services-item {
  padding: unset;
  background-color: unset;
  margin: unset;
}
.rs-services.home-style2 .services-item .services-text {
  padding: 0 0 0 25px;
}
.rs-services.home-style2 .services-item .services-text .title {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  margin: 0 0 6px;
}
.rs-services.home-style2 .services-item .services-text .services-txt {
  margin: 0;
  color: #454545;
}
.rs-services.style2 .service-wrap {
  /* padding: 15px 15px 30px; */
  padding: 5px;
  background: #fff;
  box-shadow: 0 6px 25px #eee;
  border-radius: 20px;
  margin: 10px;
  /* height: 526px; */
}
.rs-services.style2 .service-wrap .image-part {
  overflow: hidden;
  margin-bottom: 5px;
}
.rs-services.style2 .service-wrap .image-part img {
  transform: scale(1);
  transition: 0.9s;
  border-radius: 20px;
}
.rs-services.style2 .service-wrap .content-part {
  /* padding: 10px 20px 5px; */
  padding: 5px 10px;
}
.rs-services.style2 .service-wrap .content-part .title {
  font-size: 1.5rem;
  line-height: 25px;
  margin-bottom: 10px;
  display: block;
  text-overflow: ellipsis;
  /* white-space: nowrap; */
  overflow: hidden;
}
.rs-services.style2 .service-wrap:hover .image-part img,
.rs-team.style2 .team-item:hover .team-img img {
  transform: scale(1.1);
}
.rs-services.style2.rs-services-style2 .service-wrap .image-part img,
.rs-services.style2.rs-services-style2 .service-wrap:hover .image-part img,
.rs-team.style4.home-4-team-style .team-item-wrap .team-wrap .image-inner a img,
.rs-team.style4.home-4-team-style
  .team-item-wrap:hover
  .team-wrap
  .image-inner
  a
  img {
  transform: unset;
}
.rs-services.style3 .services-item {
  position: relative;
  transition: 0.5s;
}
.mb-35,
.rs-services.style3 .services-item .services-icon {
  margin-bottom: 35px;
}
.rs-services.style3 .services-item .services-icon img {
  width: 80px;
  height: 80px;
  max-width: unset;
}
.rs-services.style3 .services-item .services-content .services-title {
  font-size: 22px;
  line-height: 32px;
  font-weight: 700;
  margin-bottom: 17px;
}
.rs-services.style3 .services-item:hover {
  transform: translateY(-10px);
}
.rs-services.style4 .services-item {
  background: #fff;
  box-shadow: 0 0 45px 22px #eee;
  padding: 20px 30px 40px;
  border-radius: 5px;
  margin: 0 -7px 0 0;
  min-height: 380px;
  border: 2px solid #00a3e8;
}
.rs-services.style4 .services-item .services-icon,
.rs-services.style6 .services-box-area .services-item .services-icon {
  margin-bottom: 17px;
}
.rs-services.style4 .services-item .services-icon i {
  font-size: 47px;
  color: #00a3e8;
}
.rs-services.style4 .services-item .services-icon img,
.rs-services.style6 .services-box-area .services-item .services-icon img {
  width: 54px;
  padding: 8px 0 0;
}
.rs-services.style4 .services-item .services-content .title {
  font-size: 18px;
  line-height: 1.5;
  font-weight: 700;
  margin-bottom: 10px;
  color: #00a3e8;
}
.rs-services.style4 .services-item .services-content p,
.rs-services.style6 .services-box-area .services-item .services-content p {
  font-size: 15px;
}
.rs-services.style5 .flip-box-inner {
  position: relative;
  z-index: 1;
  margin: 0;
  backface-visibility: hidden;
}
.rs-services.style5 .flip-box-inner .flip-box-wrap {
  position: relative;
  -webkit-transform: translateZ(0);
  perspective: 1e3px;
}
.rs-services.style5 .flip-box-inner .flip-box-wrap .front-part {
  position: relative;
  bottom: 0;
  z-index: 10;
  transform: rotateY(0);
  top: 0;
  right: 0;
  left: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  height: 300px;
  background-color: initial;
  background-position: 50%;
  background-clip: padding-box;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1e3px;
  perspective: 1e3px;
  -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.2, 0.85, 0.4, 1.275);
  transition: transform 0.6s cubic-bezier(0.2, 0.85, 0.4, 1.275);
  transition: transform 0.6s cubic-bezier(0.2, 0.85, 0.4, 1.275),
    -webkit-transform 0.6s cubic-bezier(0.2, 0.85, 0.4, 1.275);
}
.rs-services.style5
  .flip-box-inner
  .flip-box-wrap
  .front-part
  .front-content-part {
  background-color: #fff;
  text-align: left;
  padding: 50px 40px;
  border-style: solid;
  border-width: 0;
  box-shadow: 0 0 10px 0 #eee;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  transition: 0.9s;
}
.rs-services.style5 .flip-box-inner .flip-box-wrap .back-part,
.rs-services.style8 .flip-box-inner .flip-box-wrap .front-part {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  -webkit-box-align: center;
  -webkit-backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.2, 0.85, 0.4, 1.275);
  right: 0;
  height: 300px;
  background-clip: padding-box;
  top: 0;
  left: 0;
  display: flex;
  backface-visibility: hidden;
  transition: transform 0.6s cubic-bezier(0.2, 0.85, 0.4, 1.275),
    -webkit-transform 0.6s cubic-bezier(0.2, 0.85, 0.4, 1.275);
}
.rs-footer.style1 .footer-top .site-map li,
.rs-services.style5
  .flip-box-inner
  .flip-box-wrap
  .front-part
  .front-content-part
  .front-icon-part,
.rs-services.style8
  .flip-box-inner
  .flip-box-wrap
  .front-part
  .front-content-part
  .front-icon-part,
.rs-single-shop
  .tab-area
  .tab-content
  .tab-pane
  .reviews-grid
  .comment-form
  p.comment-notes,
.technology-detail-inner h2,
.technology-detail-inner p {
  margin-bottom: 15px;
}
.about-box-inner
  .flip-box-wrap
  .front-part
  .front-content-part
  .front-icon-part
  .icon-part
  img,
.rs-services.style5
  .flip-box-inner
  .flip-box-wrap
  .front-part
  .front-content-part
  .front-icon-part
  .icon-part
  img,
.rs-services.style8
  .flip-box-inner
  .flip-box-wrap
  .front-part
  .front-content-part
  .front-icon-part
  .icon-part
  img {
  width: 60px;
  height: 60px;
}
.rs-services.style5
  .flip-box-inner
  .flip-box-wrap
  .front-part
  .front-content-part
  .front-title-part
  .title,
.rs-services.style8
  .flip-box-inner
  .flip-box-wrap
  .front-part
  .front-content-part
  .front-title-part
  .title {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 10px;
}
.rs-services.style5
  .flip-box-inner
  .flip-box-wrap
  .front-part
  .front-content-part
  .front-desc-part
  p {
  color: #444;
  margin: 0;
}
.rs-services.style5 .flip-box-inner .flip-box-wrap .back-part {
  position: absolute;
  z-index: -1;
  padding: 30px;
  border-radius: 5px;
  transform: rotateY(-180deg);
  -webkit-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  background-color: initial;
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  -webkit-perspective: 1e3px;
  perspective: 1e3px;
}
.rs-services-single .services-add,
.rs-services.style8 .flip-box-inner .flip-box-wrap .back-front,
.rs-services.style8 .flip-box-inner .flip-box-wrap .front-part {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
}
.rs-services.style5
  .flip-box-inner
  .flip-box-wrap
  .back-part
  .back-front-content {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  transition: 0.9s;
}
.rs-services.style5
  .flip-box-inner
  .flip-box-wrap
  .back-part
  .back-front-content
  .back-title-part
  .back-title,
.rs-services.style8
  .flip-box-inner
  .flip-box-wrap
  .back-front
  .back-front-content
  .back-title-part
  .back-title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  margin-bottom: 15px;
}
.rs-services.style5 .flip-box-inner .flip-box-wrap .back-part:before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -9;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(90deg, #00a3e8 0, #00a3e8 58%);
  content: "";
}
.rs-services.style5 .flip-box-inner:hover .flip-box-wrap .front-part,
.rs-services.style8 .flip-box-inner:hover .flip-box-wrap .front-part {
  transform: rotateY(180deg);
}
.rs-services.style5 .flip-box-inner:hover .flip-box-wrap .back-part,
.rs-services.style8 .flip-box-inner:hover .flip-box-wrap .back-front {
  transform: rotateY(0);
  z-index: 1;
}
.rs-services.style5
  .flip-box-inner:hover
  .flip-box-wrap
  .back-part
  .back-front-content {
  right: 0;
}
.rs-services.style6 {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: relative;
  margin-top: -140px;
  margin-bottom: 0;
  padding: 0;
  z-index: 2;
}
.rs-services.style6 .services-box-area {
  border-style: solid;
  border-width: 0 0 5px;
  border-color: #00a3e8;
  box-shadow: 0 11px 35px 0 #e9d1cf;
}
.rs-services.style6 .services-box-area .services-item {
  border-style: dashed;
  border-width: 0 1px 0 0;
  border-color: #00a3e83d;
  margin: 0 8px;
  padding: 40px 35px;
}
.rs-services.style6 .services-box-area .services-item .services-content .title {
  font-size: 18px;
  line-height: 1.5;
  font-weight: 700;
  margin-bottom: 10px;
}
.rs-services.style7 .services-item .services-icon img {
  height: 50px;
  width: 50px;
  max-width: unset;
}
.rs-services.style7 .services-item .services-content {
  padding: 0 20px 0 26px;
}
.rs-services.style7 .services-item .services-content .title {
  font-size: 20px;
  line-height: 34px;
  font-weight: 700;
  color: #fff;
  margin: 0;
}
.rs-services.style7 .services-item .services-content .services-txt {
  color: #fff;
  margin: 0;
}
.rs-services.style8 .flip-box-inner {
  position: relative;
  z-index: 1;
  padding: 0;
  backface-visibility: hidden;
}
.rs-services.style8 .flip-box-inner .flip-box-wrap {
  position: relative;
  -webkit-transform: translateZ(0);
  perspective: 1000px;
}
.rs-services.style8 .flip-box-inner .flip-box-wrap .front-part {
  position: relative;
  bottom: 0;
  z-index: 10;
  border-style: solid;
  border-width: 0;
  -webkit-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  background-color: transparent;
  -webkit-perspective: 1000px;
  perspective: 1000px;
}
.rs-services.style8
  .flip-box-inner
  .flip-box-wrap
  .front-part
  .front-content-part {
  background-color: #fff;
  text-align: left;
  padding: 38px;
  margin: 0 6px;
  border-style: solid;
  border-width: 0;
  border-radius: 4px;
  box-shadow: 3px 7px 25px 0 rgba(0, 0, 0, 0.05);
}
.rs-services.style8
  .flip-box-inner
  .flip-box-wrap
  .front-part
  .front-content-part
  .front-desc-part
  p {
  font-size: 15px;
  color: #444;
  margin-bottom: 0;
}
.rs-services.style8 .flip-box-inner .flip-box-wrap .back-front {
  transform: rotateY(-180deg);
  position: absolute;
  z-index: -1;
  padding: 30px;
  margin: 0 6px;
  border-radius: 4px;
  top: 0;
  right: 0;
  left: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  -ms-flex-align: center;
  height: 300px;
  background-color: transparent;
  background-clip: padding-box;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000px;
  perspective: 1000px;
  -webkit-transition: -webkit-transform 0.6s cubic-bezier(0.2, 0.85, 0.4, 1.275);
  transition: transform 0.6s cubic-bezier(0.2, 0.85, 0.4, 1.275),
    -webkit-transform 0.6s cubic-bezier(0.2, 0.85, 0.4, 1.275);
}
.rs-services.style8 .flip-box-inner .flip-box-wrap .back-front:before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -9;
  width: 100%;
  height: 100%;
  background-color: transparent;
  content: "";
}
.rs-services.style8 .flip-box-inner:hover .flip-box-wrap .back-front:before {
  background-color: transparent;
  background-image: linear-gradient(90deg, #4caf50 0, #4caf50 58%);
  border-radius: 4px;
}
.rs-services.style1 {
  margin-top: -120px;
}
.rs-services.style1 .service-wrap {
  box-shadow: 0 6px 30px 0 rgba(0, 0, 0, 0.08);
  z-index: 9;
  position: relative;
  border-radius: 0 100px 0 0;
}
.rs-services.style1 .service-wrap .service-grid {
  padding: 50px 45px 40px;
  transition: 0.3s;
  background: #fff;
  transform: scale(1);
  border-right: 1px solid;
  border-color: #f5f5f5;
}
.rs-services.style1 .service-wrap .service-grid.bdru {
  border-radius: 0 100px 0 0;
}
.rs-services.style1 .service-wrap .service-grid .service-icon img {
  max-width: 51px;
  height: 56px;
  filter: grayscale(1);
  transition: 0.3s;
}
.rs-services.style1 .service-wrap .service-grid .btn-wrap .readmore {
  font-weight: 600;
  display: inline-flex;
  align-items: center;
}
.rs-services.style1 .service-wrap .service-grid .btn-wrap .readmore .btn-arrow {
  position: relative;
  width: 20px;
  height: 16px;
  overflow: hidden;
  margin-left: 8px;
}
.rs-services.style1
  .service-wrap
  .service-grid
  .btn-wrap
  .readmore
  .btn-arrow:after,
.rs-services.style1
  .service-wrap
  .service-grid
  .btn-wrap
  .readmore
  .btn-arrow:before {
  position: absolute;
  content: "\f114";
  font-family: Flaticon;
  top: 50%;
  transform: translateY(-50%);
  color: #043d72;
  transition: 0.3s;
}
.rs-services.style1 .service-wrap .service-grid.br-none {
  border-right: none;
}
.rs-services.style1 .service-wrap .service-grid:hover {
  transform: scale(1.1);
  position: relative;
  z-index: 1;
  box-shadow: 0 6px 30px 0 rgba(0, 0, 0, 0.08);
}
.rs-services.style1 .service-wrap .service-grid:hover .service-icon img {
  filter: grayscale(0);
}
.rs-services.style1.modify .service-wrap {
  border-radius: 0;
}
.rs-services.style1.modify .service-wrap .service-grid {
  border-bottom: 2px solid #f5f5f5;
}
.rs-services-single .services-add {
  padding: 50px 35px 28px;
}
.rs-services-single .services-add .title {
  font-size: 24px;
  line-height: 42px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 30px;
}
.rs-services-single .services-add a {
  font-size: 30px;
  line-height: 40px;
  font-weight: 700;
  color: #fff;
}
.rs-services-single .brochures {
  background: #ebebeb;
  padding: 35px 30px 45px;
}
.rs-services-single .brochures .title-part .title {
  font-size: 28px;
  font-weight: 700;
  display: block;
  position: relative;
}
.rs-services-single .brochures .title-part .title:before {
  content: "";
  position: absolute;
  border: 0;
  width: 65px;
  height: 5px;
  background: #00a3e8;
  z-index: 1;
  margin-left: 0;
  left: 0;
  bottom: -20px;
}
.rs-services-single .brochures .btn-wrapper {
  position: relative;
  text-align: center;
  font-weight: 400;
  display: inline-block;
}
.rs-services-single .brochures .btn-wrapper .dual-btn {
  padding: 15px 30px;
  font-size: 17px;
  font-weight: 600;
  background-color: #00a3e8;
  color: #fff;
  margin: 0 -4px;
}
.rs-services-single .brochures .btn-wrapper .dual-btn-connector {
  position: absolute;
  z-index: 9;
  overflow: hidden;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: #fff;
  box-shadow: 0 0 0 5px #bfb2b2;
  color: #27374c;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 30px;
  left: 47%;
}
.rs-services-single .brochures .btn-wrapper .rselement-dual-btn {
  padding: 15px 25px;
  border-radius: 0;
  font-size: 17px;
  font-weight: 600;
  background-color: #132235;
  color: #fff;
}
.rs-services-single .dot {
  list-style: disc;
  margin: 0 0 1.3em 3em;
}
.our-team .icon11,
.profile-work ul,
.term12 ul {
  list-style: none;
}
.rs-services-single .widget-item li {
  border-top-style: solid;
  border-top-width: 1px;
  border-color: #eee;
  width: 79%;
  padding-bottom: calc(16px);
  padding-top: 16px;
}
.rs-services-single .widget-item li a {
  font-size: 16px;
  font-weight: 600;
  color: #000;
}
.rs-services-single .widget-item li a i {
  color: #000;
  font-size: 16px;
  font-weight: 900;
}
.rs-services-single .widget-item li a span {
  color: #020202;
  padding-left: 16px;
}
.rs-services-single .widget-item li:first-child {
  border-top: none;
}
.rs-footer.style1 .footer-top .footer-social li,
.rs-services-single .social-icons li,
.rs-testimonial.style1 .item .rating li {
  display: inline;
  margin-right: 5px;
}
.rs-services-single .social-icons li a i {
  padding: 0;
  font-size: 25px;
  background: #3b5998;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 0;
  color: #fff;
  text-align: center;
}
.rs-services-single .social-icons li a.blue-bg i {
  background: #1da1f2;
}
.rs-services-single .social-icons li a.light-bg i {
  background: #0a0a0a;
}
.rs-services-single .social-icons li a.red-bg i {
  background: #cd201f;
}
.certifite img,
.itemteam,
.loader,
.notfound,
.pricingTable,
.rs-about .sec-title3,
.rs-contact .contact-icon,
.rs-partner .item,
.rs-services-single .call-us .contact-widget {
  text-align: center;
}
.rs-services-single .call-us .contact-widget .icon-part {
  margin-bottom: 40px;
}
.rs-services-single .call-us .contact-widget .icon-part img {
  max-width: 84px;
}
.rs-services-single .call-us .contact-widget .title {
  font-size: 36px;
  line-height: 46px;
  font-weight: 700;
  margin: 0;
}
.rs-why-choose.style1 .choose-content {
  padding: 100px 130px;
}
.rs-team.style1 .team-wrap {
  position: relative;
  transition: 0.3s;
}
.rs-team.style1 .team-wrap .image-wrap,
.rs-team.style3 .team-item,
.widget-area .widget_recent_post {
  position: relative;
  overflow: hidden;
}
.rs-team.style1 .team-wrap .image-wrap .social-icons {
  position: absolute;
  top: 0;
  right: -50px;
  width: 50px;
  background: #00a3e8;
  padding: 12px 0 0;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  transition: 0.4s;
}
.rs-team.style1 .team-wrap .image-wrap .social-icons a i {
  background: 0 0;
  color: #fff;
  font-size: 15px;
  width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  transition: 0.3s;
  border-radius: 50%;
  margin: 0;
}
.rs-team.style1 .team-wrap .image-wrap .social-icons:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-top: 50px solid #00a3e8;
  border-right: 50px solid transparent;
  bottom: 0;
  left: 0;
  display: block;
  top: 100%;
}
.rs-team.style1 .team-wrap .team-content {
  text-align: center;
  margin: 0;
  padding: 20px 20px 25px;
  background-color: #191919;
  position: unset;
  opacity: 1;
}
.rs-team.style1 .team-wrap .team-content .team-name {
  font-size: 20px;
  font-weight: 600;
  line-height: 34px;
  margin-bottom: 0;
  text-transform: capitalize;
}
.rs-team.style1 .team-wrap .team-content .team-title {
  font-size: 13px;
  font-weight: 500;
  line-height: 3em;
  color: #c5c2c2;
  text-transform: uppercase;
}
.rs-team.style1 .team-wrap:hover .image-wrap .social-icons {
  right: 0;
  width: 50px;
  opacity: 1;
  visibility: visible;
}
.rs-team.style1.team-style1 .team-wrap {
  box-shadow: 0 5px 20px #eee;
}
.rs-team.style1.team-style1 .team-wrap .image-wrap a img {
  opacity: 1;
  transition: 0.3s ease-in-out;
  transform: scale(1.1);
}
.rs-team.style1.team-style1 .team-wrap:hover .image-wrap a img {
  opacity: 0.7;
  transform: scale(1);
}
.rs-team.style2 .team-item {
  position: relative;
  overflow: hidden;
  padding-bottom: 95px;
  background: #fff;
}
.rs-team.style2 .team-item .team-img img,
.rs-team.style3 .team-item .team-img img {
  transition: 0.4s;
}
.rs-team.style2 .team-item .team-content {
  height: 100px;
  width: 100%;
  bottom: 0;
  position: absolute;
  padding: 20px;
  color: #444;
  text-align: center;
  overflow: hidden;
  background: #fff;
  transition: 0.3s;
}
.rs-team.style2 .team-item .team-content .team-info .name {
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
  margin: 0;
  text-transform: capitalize;
}
.rs-team.style2 .team-item .team-content .team-info .post {
  font-size: 13px;
  line-height: 26px;
  font-weight: 500;
  color: #333;
  text-transform: uppercase;
}
.rs-team.style2 .team-item .team-content .social-icon {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  z-index: 100;
  left: 50%;
  transform: translateX(-50%) scale(0.5);
  transition: 0.4s ease-in-out;
  opacity: 0;
}
.rs-team.style2 .team-item .team-content .social-icon li {
  display: inline-block;
  margin-right: 30px;
}
.rs-team.style2 .team-item .team-content .social-icon li a {
  width: 25px;
  height: 25px;
  line-height: 25px;
  color: #fff;
  transition: 0.3s;
  background: 0 0;
}
.rs-team.style2 .team-item .team-content .social-icon li a i {
  font-size: 30px;
  color: #fff;
}
.rs-team.style2 .team-item .team-content:before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 3px;
  background: #00a3e8;
  transition: 0.4s;
  left: 0;
}
.rs-team.style2 .team-item:hover .team-content {
  opacity: 1;
  width: 100%;
  visibility: visible;
  transform: translate(0, 0);
  bottom: 0;
  height: 140px;
  color: #fff;
  background: #00a3e8;
}
.rs-team.style2 .team-item:hover .team-content .social-icon {
  bottom: 20px;
  opacity: 1;
}
.job-instructor-title,
.main-content,
.rs-contact.main-home .contact-map,
.rs-cta.style1,
.rs-team.style3 .team-item .team-img,
.rs-team.style4 .team-item-wrap .team-wrap .image-inner {
  overflow: hidden;
}
.rs-team.style3 .team-item .team-content {
  padding: 25px 20px 30px;
  color: #444;
  text-align: center;
  overflow: hidden;
  background: #fff;
  transition: 0.3s;
}
.rs-team.style3 .team-item .team-content .team-info .name {
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 6px;
}
.rs-team.style3 .team-item .team-content .team-info .post {
  font-size: 13px;
  line-height: 26px;
  font-weight: 500;
  color: #333;
  text-transform: uppercase;
  display: block;
  margin-bottom: 7px;
}
.rs-team.style3 .team-item .team-content .social-icon li a {
  font-size: 15px;
  width: 25px;
  height: 25px;
  line-height: 25px;
  color: #fff;
  transition: 0.3s;
  background: 0 0;
}
.rs-team.style3 .team-item .team-content .social-icon li a i {
  color: #4a4a4a;
}
.rs-team.style3 .team-item:hover .team-img img {
  transform: scale(1.2);
}
.rs-team.style4 .team-item-wrap .team-wrap .image-inner a img {
  transform: scale(1);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.rs-team.style4 .team-item-wrap .team-content {
  padding: 20px;
}
.rs-team.style4 .team-item-wrap .team-content .person-name {
  font-size: 22px;
  font-weight: 600;
  /* line-height: 17px; */
  margin-bottom: 20px;
}
.rs-team.style4 .team-item-wrap .team-content .person-name a {
  color: #0a0a0a;
  font-size: 18px;
}
.rs-team.style4 .team-item-wrap .team-content .designation {
  display: block;
  font-size: 15px;
  font-weight: 400;
  line-height: 26px;
  color: #00a3e8;
  margin-bottom: 15px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.rs-team.style4 .team-item-wrap .team-content .team-social li {
  display: inline-block;
  margin-right: 16px;
}
.rs-team.style4 .team-item-wrap .team-content .team-social li a i {
  font-size: 18px;
  line-height: 18px;
  font-weight: 400;
  color: #00a3e8;
}
.rs-team.style4 .team-item-wrap:hover .team-wrap .image-inner a img {
  border: 2px dotted #000;
}
.rs-team.style4.home-4-team-style .team-item-wrap .team-wrap .image-inner {
  border-radius: unset;
}
.rs-team.style4.home-4-team-style .team-item-wrap .team-content {
  padding: 20px;
  background: #fff;
  box-shadow: 0 6px 25px #eee;
}
.rs-team.style4.home-4-team-style .team-item-wrap .team-content .designation {
  display: block;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: #454545;
  margin-bottom: 15px;
}
.rs-team.style4.home-4-team-style .owl-stage-outer {
  padding: 20px;
  margin: -20px;
}
.rs-team-Single .btm-info-team {
  position: relative;
  box-shadow: 0 0 32px 0 rgba(7, 28, 31, 0.05);
  background: #fff;
  margin-bottom: 70px;
  padding: 40px;
}
.rs-team-Single .btm-info-team .images-part {
  padding: 0 30px 0 0;
}
.rs-team-Single .btm-info-team .con-info .designation-info {
  font-size: 15px;
  line-height: 26px;
  font-weight: 600;
  color: #00a3e8;
  text-transform: uppercase;
  display: block;
}
.rs-team-Single .btm-info-team .con-info .title {
  font-size: 36px;
  line-height: 46px;
  font-weight: 700;
  color: #0a0a0a;
  margin-bottom: 15px;
}
.rs-team-Single .btm-info-team .con-info .short-desc {
  font-size: 15px;
  line-height: 26px;
  font-weight: 400;
  color: #454545;
  margin-bottom: 25px;
}
.rs-team-Single .btm-info-team .con-info .team-title {
  font-size: 28px;
  line-height: 38px;
  font-weight: 700;
  color: #0a0a0a;
  margin-bottom: 15px;
}
.rs-team-Single .btm-info-team .con-info .ps-informations .personal-info li {
  margin: 5px 0 10px;
  padding: 0;
  display: block;
}
.rs-team-Single
  .btm-info-team
  .con-info
  .ps-informations
  .personal-info
  li
  span
  i:before {
  color: #00a3e8;
  margin-right: 18px;
  position: relative;
  top: 2px;
  font-weight: 500;
  display: inline-block;
  text-align: center;
}
.rs-team-Single .btm-info-team .con-info .ps-informations .personal-info li a,
.rs-team-Single .btm-info-team .con-info .ps-informations .social-info li a {
  color: #555;
}
.rs-single-shop .single-price-info .some-text,
.rs-team-Single .btm-info-team .con-info .ps-informations .social-info {
  margin-top: 15px;
}
.rs-team-Single .btm-info-team .con-info .ps-informations .social-info li {
  display: inline-block;
  margin-right: 18px;
}
.rs-team-Single .btm-info-team .con-info:before {
  content: "\f10b";
  font-size: 250px;
  position: absolute;
  right: 92px;
  color: #0a0a0a;
  font-family: Flaticon;
  height: 100%;
  top: 74px;
  opacity: 0.02;
}
.rs-testimonial.main-home .testi-image img {
  position: relative;
  max-width: 600px;
  right: 10%;
}
.rs-testimonial.home5-testi-style
  .testi-box-style
  .testi-item
  .item-content
  .icon-img
  img,
.rs-testimonial.main-home .testi-item .item-content .icon-img img {
  width: 75px;
}
.rs-testimonial.main-home .testi-item .item-content .desc {
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  padding: 40px 0 30px;
}
.rs-testimonial.main-home .testi-item .testi-content .image-wrap img {
  width: 80px;
  height: 80px;
  border-radius: 50px;
  margin: 0 auto;
}
.rs-testimonial.main-home
  .testi-item
  .testi-content
  .testi-information
  .testi-name {
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  color: #fff;
  padding-bottom: 5px;
  display: block;
}
.rs-testimonial.home5-testi-style
  .testi-box-style
  .testi-item
  .testi-content
  .testi-information
  .testi-title,
.rs-testimonial.main-home
  .testi-item
  .testi-content
  .testi-information
  .testi-title {
  color: #878787;
}
.rs-testimonial.main-home .rs-carousel .owl-dots {
  text-align: left;
  margin-top: 45px;
}
.rs-testimonial.style2 .testi-wrap {
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 30px 40px 60px;
}
.rs-testimonial.style2 .testi-wrap .item-content span img {
  width: 48px;
}
.rs-testimonial.style2 .testi-wrap .item-content p {
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  padding: 15px 0 10px;
}
.rs-testimonial.style2 .testi-wrap .testi-content {
  display: flex;
  justify-content: flex-start;
}
.rs-testimonial.style2 .testi-wrap .testi-content .image-wrap img {
  border-radius: 50px;
  width: 80px;
  height: 80px;
}
.rs-testimonial.style2 .testi-wrap .testi-content .testi-information {
  padding: 0 0 0 25px;
  text-align: left;
}
.rs-testimonial.style2
  .testi-wrap
  .testi-content
  .testi-information
  .testi-name {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  color: #fff;
}
.rs-testimonial.style2
  .testi-wrap
  .testi-content
  .testi-information
  .testi-title {
  font-weight: 600;
  color: #878787;
  padding-bottom: 10px;
  display: block;
}
.rs-testimonial.style2
  .testi-wrap
  .testi-content
  .testi-information
  .ratting-img
  img {
  width: 100px;
}
.rs-testimonial.style3 .testi-item {
  background-color: #ebebeb;
  padding: 40px 20px;
  text-align: center;
}
.rs-testimonial.style3 .testi-item .testi-img img {
  width: 70px;
  height: 70px;
}
.rs-testimonial.style3 .testi-item p {
  color: #0a0a0a;
  font-size: 18px;
  line-height: 35px;
  margin-bottom: 0;
  padding: 19px 5px 20px 19px;
}
.rs-testimonial.style3 .testi-item .testi-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.rs-testimonial.style3 .testi-item .testi-content .testi-img {
  padding-right: 15px;
}
.rs-testimonial.style3 .testi-item .testi-content .testi-img img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}
.rs-testimonial.style3 .testi-item .testi-content .author-part .name {
  font-size: 16px;
  font-weight: 600;
  line-height: 35px;
  color: #0a0a0a;
  letter-spacing: 1px;
  margin-bottom: 0;
  display: block;
}
.rs-testimonial.style3 .testi-item .testi-content .author-part .designation {
  font-size: 14px;
  line-height: 24px;
  color: #0a0a0a;
  display: block;
}
.rs-testimonial.style4 .testi-item .testi-wrap {
  padding: 40px;
  background-color: #00a3e836;
  text-align: center;
  margin: 0 -13px 0 0;
}
.rs-testimonial.style4 .testi-item .testi-wrap .image-wrap {
  justify-content: center;
  display: flex;
  flex-wrap: nowrap;
}
.rs-testimonial.style4 .testi-item .testi-wrap .image-wrap img {
  width: 85px;
  height: 85px;
  border-radius: 50%;
}
.rs-testimonial.style4 .testi-item .testi-wrap .item-contents p {
  color: #fff;
  font-size: 15px;
  font-style: italic;
  padding: 30px 0 0;
  margin-bottom: 20px;
}
.rs-testimonial.style4 .testi-item .testi-wrap .testi-information .testi-name {
  font-size: 17px;
  font-weight: 600;
  color: #fff;
}
.rs-testimonial.style4 .testimonial-overly-bg {
  background-color: #ffffff61;
}
.rs-testimonial.style4.home4-testi-style .testi-item .testi-wrap {
  background-color: #ffffffb0;
}
.profile-head h5,
.rs-contact.faq-style .contact-item .content-text a,
.rs-testimonial.style4.home4-testi-style
  .testi-item
  .testi-wrap
  .item-contents
  p,
.rs-testimonial.style4.home4-testi-style
  .testi-item
  .testi-wrap
  .testi-information
  .testi-name,
.rs-testimonial.style4.home4-testi-style
  .testi-item
  .testi-wrap
  .testi-information
  .testi-title {
  color: #333;
}
.rs-testimonial.home5-testi-style .testi-box-style {
  background: #fff;
  box-shadow: 0 6px 25px #eee;
  padding: 20px 43px 57px 58px;
}
.rs-testimonial.home5-testi-style
  .testi-box-style
  .testi-item
  .item-content
  .icon-img {
  top: 36px;
  position: absolute;
  left: 0;
}
.rs-testimonial.home5-testi-style
  .testi-box-style
  .testi-item
  .item-content
  .desc {
  color: #333;
  font-size: 17px;
  font-weight: 500;
  font-style: italic;
  padding: 124px 40px 15px 0;
}
.rs-testimonial.home5-testi-style .testi-box-style .testi-item .testi-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.rs-testimonial.home5-testi-style
  .testi-box-style
  .testi-item
  .testi-content
  .image-wrap
  img {
  width: 80px;
  height: 80px;
  border-radius: 50px;
  position: relative;
}
.rs-testimonial.home5-testi-style
  .testi-box-style
  .testi-item
  .testi-content
  .testi-information
  .testi-name {
  font-size: 20px;
  font-weight: 500;
  color: #333;
}
.rs-testimonial.home5-testi-style .testi-box-style .rs-carousel .owl-dots {
  margin-top: 40px;
  text-align: left;
}
.rs-testimonial.style1 .left-radius {
  border-radius: 100px 0 0;
}
.rs-testimonial.style1 .item .testi-img img {
  margin: 0 auto;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 4px solid #fff;
}
.rs-testimonial.style1 .item .rating {
  margin-bottom: 13px;
}
.rs-testimonial.style1 .item .rating li i {
  color: #e5e94d;
  font-size: 26px;
}
.rs-testimonial.style1 .item .author-detail .desc {
  color: #f9f9f9;
  max-width: 90%;
  margin: 0 auto 18px;
  height: 150px;
}
.rs-testimonial.style1 .item .author-detail .name {
  color: #fff;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 3px;
}
.rs-testimonial.style1 .item .author-detail .designation {
  color: #f9f9f9;
  font-size: 14px;
}
.rs-testimonial.style1 .center .item {
  border-right: 1px solid;
  border-left: 1px solid;
  border-color: #1d507f;
}
.rs-footer .bg-wrap .newslatter-wrap .footer-newsletter .pattern,
.rs-testimonial.style1 .pattern-img .common {
  position: absolute;
  content: "";
}
.rs-testimonial.style1 .pattern-img .common.img1 {
  top: 93px;
  left: 200px;
  opacity: 0.1;
  max-width: 190px;
  animation: 30s linear infinite rotate-anim;
  -webkit-animation: 30s linear infinite rotate-anim;
  -moz-animation: 30s linear infinite rotate-anim;
}
.rs-testimonial.style1 .pattern-img .common.img2 {
  bottom: 0;
  left: 0;
}
.rs-testimonial.style1 .pattern-img .common.img3 {
  top: 0;
  right: 0;
  max-width: 248px;
  margin: 0 auto 0 0;
}
.rs-partner .item a img {
  max-width: 150px;
  margin: 0 auto;
  height: auto;
}
.rs-partner .owl-carousel .owl-nav [class*="owl-"] {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: #043d72;
  color: #fff;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  opacity: 0 !important;
  visibility: hidden !important;
  transition: 0.3s;
  outline: 0;
  border: none;
}
.rs-partner .owl-carousel .owl-nav [class*="owl-"] i {
  font-size: 0;
  position: relative;
}
.rs-partner .owl-carousel .owl-nav [class*="owl-"].owl-prev {
  left: 15px;
}
.rs-partner .owl-carousel .owl-nav [class*="owl-"].owl-next {
  right: 15px;
}
.rs-partner .owl-carousel .owl-nav [class*="owl-"]:hover {
  background-color: #e94d65;
}
.rs-partner .owl-carousel:hover [class*="owl-"] {
  opacity: 1 !important;
  visibility: visible !important;
}
.rs-partner .owl-carousel:hover [class*="owl-"] .owl-prev {
  left: -50px;
}
.rs-partner .owl-carousel:hover [class*="owl-"] .owl-next {
  right: -50px;
}
.rs-achievement.style1 .img-part {
  position: relative;
  right: -100px;
}
.rs-achievement.style1 .img-part img {
  border-radius: 50px 0 0;
}
.rs-achievement.style1 .rs-counter {
  display: flex;
  justify-content: center;
  border-top: 1px solid #e8e8e8;
}
.rs-achievement.style1 .rs-counter .rs-counter-list {
  text-align: center;
  width: 100%;
  transition: 0.3s;
  padding: 40px 0 35px;
}
.blog-card,
.blog-details-desc .article-leave-comment .form-group,
.rs-achievement.style1 .rs-counter .rs-counter-list .counter-icon,
.single-blog-card,
.single-choose-card {
  margin-bottom: 25px;
}
.rs-achievement.style1 .rs-counter .rs-counter-list .counter-icon i {
  height: 46.3px;
  line-height: 46.3px;
  display: block;
  color: #e94d65;
}
.rs-achievement.style1 .rs-counter .rs-counter-list .counter-icon i:before {
  font-size: 48px;
}
.rs-achievement.style1
  .rs-counter
  .rs-counter-list
  .counter-icon
  i.flaticon-group:before {
  font-size: 65px;
}
.rs-achievement.style1
  .rs-counter
  .rs-counter-list
  .counter-icon
  i.flaticon-briefing:before {
  font-size: 46px;
}
.rs-achievement.style1 .rs-counter .rs-counter-list .counter-number {
  font-size: 40px;
  line-height: 1;
  color: #222;
  font-weight: 700;
  margin-bottom: 8px;
}
.rs-achievement.style1 .rs-counter .rs-counter-list .counter-number:after {
  content: "+";
}
.rs-achievement.style1 .rs-counter .rs-counter-list .counter-text {
  font-weight: 500;
  text-transform: capitalize;
}
.rs-achievement.style1 .rs-counter:hover .rs-counter-list:hover,
.rs-achievement.style1 .rs-counter:not(:hover) .active {
  box-shadow: 0 6px 30px 0 rgba(0, 0, 0, 0.08);
}
.rs-achievement.style1 .pattern-img {
  position: absolute;
  content: "";
  right: 0;
  top: 141px;
  z-index: -1;
}
.rs-estimate .image-part {
  position: relative;
  left: -70px;
}
.rs-estimate .estimate-info li {
  position: relative;
  padding-left: 30px;
  margin-bottom: 35px;
}
.rs-estimate .estimate-info li .title {
  font-weight: 800;
  color: #222;
}
.rs-estimate .estimate-info li:before {
  position: absolute;
  content: "";
  left: 0;
  top: 10px;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #043d72;
}
.rs-estimate .pattern-img {
  position: absolute;
  content: "";
  left: 50px;
  top: 100px;
  z-index: -1;
}
.rs-cta.style1 .pattern-right {
  position: absolute;
  content: "";
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.rs-cta.style1 .pattern-left {
  position: absolute;
  content: "";
  left: 20px;
  bottom: 0;
}
.loader .loader-container,
.loader .loader-container:before,
.loader .loader-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.rs-shop-part .woocommerce-result-count {
  font-size: 15px;
  line-height: 26px;
  color: #454545;
  font-weight: 400;
  margin: 0;
}
.rs-shop-part .from-control {
  float: right;
  font-size: 15px;
  color: #454545;
  font-weight: 400;
  vertical-align: top;
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 5px;
}
.rs-shop-part .product-list .image-product .overley i {
  position: absolute;
  bottom: 150px;
  right: 13px;
  background: 0 0;
  opacity: 0;
  visibility: hidden;
  transition: 0.4s;
}
.rs-shop-part .product-list .image-product .overley i:before {
  font-size: 30px;
  color: #fff;
  font-weight: 400;
  background: #00a3e8;
  border: none;
  padding: 8px 10px;
  height: 45px;
  width: 45px;
  line-height: 45px;
  border-radius: 5px;
}
.rs-shop-part .product-list .image-product .onsale {
  font-size: 13px;
  color: #fff;
  font-weight: 700;
  line-height: 40px;
  background: #00a3e8;
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: 13px;
  right: 13px;
}
.rs-shop-part .product-list .content-desc .loop-product-title {
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
  margin-bottom: 10px;
  padding-top: 30px;
}
.rs-shop-part .product-list .content-desc .price {
  font-size: 16px;
  line-height: 27px;
  color: #00a3e8;
  font-weight: 600;
  margin: 0;
}
.rs-shop-part .product-list .content-desc .price del {
  opacity: 0.6;
  padding-right: 10px;
}
.rs-shop-part .product-list:hover .image-product .overley i {
  opacity: 1;
  visibility: visible;
  bottom: 13px;
}
.rs-single-shop .single-product-image .images-single {
  z-index: 1 !important;
}
.rs-single-shop .single-product-image img {
  width: 100%;
}
.rs-single-shop .single-price-info .product-title {
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 12px;
}
.rs-single-shop .single-price-info .single-price {
  color: #00a3e8;
  font-weight: 600;
}
.rs-single-shop .single-price-info form input {
  height: 40px;
  width: 70px;
  line-height: 40px;
  text-align: center;
  padding-left: 10px;
  border: 1px solid rgba(54, 54, 54, 0.1);
  outline: 0;
}
.rs-single-shop .single-price-info p.category {
  margin: 0;
  padding-top: 25px;
  border-top: 1px solid #e6e6e6;
  font-size: 14px;
}
.rs-single-shop .single-price-info p.category span {
  font-weight: 700;
  padding-right: 10px;
}
.rs-single-shop .single-price-info p.category a {
  color: #454545;
}
.rs-single-shop .tab-area {
  margin-top: 50px;
}
.rs-single-shop .tab-area ul.nav-tabs {
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 32px;
}
.loginbtn span,
.rs-single-shop .tab-area ul.nav-tabs li {
  margin-right: 10px;
}
.rs-single-shop .tab-area ul.nav-tabs li .nav-link {
  padding: 20px 45px;
  display: inline-block;
  border-radius: 0;
  font-size: 18px;
  background: #fbfbfb;
  border: 1px solid #fbfbfb;
  color: #0a0a0a;
  font-weight: 400;
}
.rs-single-shop .tab-area .tab-content .tab-pane .tab-title {
  font-weight: 700;
  margin-bottom: 34px;
}
.rs-single-shop .tab-area .tab-content .tab-pane .reviews-grid .top-area {
  margin-bottom: 5px;
}
.rs-single-shop .tab-area .tab-content .tab-pane .reviews-grid .top-area p {
  margin-bottom: 23px;
}
.rs-single-shop .tab-area .tab-content .tab-pane .reviews-grid .top-area h6 {
  font-size: 15px;
  font-weight: 600;
  color: #454545;
}
.rs-single-shop .tab-area .tab-content .tab-pane .reviews-grid .comment-form {
  max-width: 600px;
}
.rs-single-shop
  .tab-area
  .tab-content
  .tab-pane
  .reviews-grid
  .comment-form
  .placeholder-cntrl
  label {
  font-weight: 600;
  display: block;
}
.rs-single-shop
  .tab-area
  .tab-content
  .tab-pane
  .reviews-grid
  .comment-form
  .placeholder-cntrl
  input {
  border: 1px solid rgba(54, 54, 54, 0.1);
  padding: 10px 15px;
  margin-bottom: 10px;
  width: 100%;
}
.rs-single-shop
  .tab-area
  .tab-content
  .tab-pane
  .reviews-grid
  .comment-form
  .placeholder-cntrl
  textarea {
  width: 100%;
  padding: 10px 15px;
  height: 75px;
  border: 1px solid rgba(54, 54, 54, 0.1);
  margin-bottom: 20px;
}
.rs-single-shop
  .tab-area
  .tab-content
  .tab-pane
  .reviews-grid
  .comment-form
  .ratings {
  font-weight: 600;
  margin-bottom: 18px;
  display: block;
}
.rs-single-shop
  .tab-area
  .tab-content
  .tab-pane
  .reviews-grid
  .comment-form
  .ratings
  ul
  li {
  display: inline;
}
.rs-single-shop
  .tab-area
  .tab-content
  .tab-pane
  .reviews-grid
  .comment-form
  .ratings
  ul
  li
  i {
  color: #00a3e8;
  font-weight: 400;
}
.rs-footer.style1 {
  background: url(../../public/images/footer.jpg) top/cover no-repeat;
}
.rs-footer.style1 .footer-top {
  padding: 46px 0 46px;
  background: #00003c;
}
.rs-footer.style1 .footer-top .footer-logo img {
  height: 65px;
}
.rs-footer.style1 .footer-top .footer-title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  color: #fff;
  letter-spacing: 0;
  position: relative;
  margin-bottom: 40px;
  text-transform: uppercase;
  padding-bottom: 14px;
}
.rs-footer.style1 .footer-top .footer-title:after {
  content: "";
  width: 50px;
  height: 4px;
  background: #00a3e8;
  position: absolute;
  bottom: -4px;
  right: 0;
  left: 0;
  margin-left: 0;
  border-radius: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  z-index: 0;
}
.rs-footer.style1 .footer-top .site-map li a {
  font-size: 15px;
  color: #fff;
}
.rs-footer.style1 .footer-top .site-map li a:hover {
  color: #00a3e8;
  padding-left: 10px;
}
.blog-card .blog-content p,
.blog-details-desc .article-content .list li:last-child,
.pricingTable .pricing-content li:last-child,
.rs-footer.style1 .footer-top .site-map li:last-child,
.widget-area .widget_categories .list li:last-child,
.widget-area .widget_recent_post .item:last-child {
  margin-bottom: 0;
}
.rs-footer.style1 .footer-top .footer-social li a i {
  padding: 0;
  font-size: 15px;
  margin-right: 3px;
  transition: 0.8s;
  background: #1c2024;
  width: 35px;
  height: 35px;
  line-height: 35px;
  border-radius: 0;
  color: #fff;
  text-align: center;
}
.rs-footer.style1 .footer-top .footer-social li a i:hover {
  color: #fff;
  background: #00a3e8;
}
.rs-footer.style1 .footer-top .address-widget li {
  padding: 0 0 16px 40px;
  position: relative;
}
.rs-footer.style1 .footer-top .address-widget li i {
  color: #00a3e8;
  position: absolute;
  left: 0;
}
.rs-footer.style1 .footer-top .address-widget li i:before {
  font-size: 20px;
}
.rs-footer.style1 .footer-bottom .copyright p,
.rs-footer.style1 .footer-top .widget-desc {
  margin: 0;
  color: #fff;
}
.rs-footer.style1 .footer-top p {
  margin: 30px 0 0;
  position: relative;
}
.rs-footer.style1 .footer-top p input[type="email"] {
  border: none;
  width: 92%;
  font-size: 13px;
  padding: 20px 60px 20px 20px;
  margin: 0;
  color: #0a0a0a;
  overflow: hidden;
  border-radius: 0;
  background: #fff;
}
.rs-footer.style1 .footer-top p input[type="submit"] {
  border: none;
  padding: 17px 30px;
  font-size: 14px;
  background: #00a3e8;
  color: #fff;
  border-radius: 0;
  margin-top: 15px;
  text-transform: uppercase;
  font-weight: 600;
  transition: 0.5s;
}
.rs-footer.style1 .footer-top p ::-webkit-input-placeholder {
  color: #0a0a0a;
  opacity: 1;
}
.rs-footer.style1 .footer-top p ::-moz-placeholder {
  color: #0a0a0a;
  opacity: 1;
}
.rs-footer.style1 .footer-top p :-ms-input-placeholder {
  color: #0a0a0a;
  opacity: 1;
}
.rs-footer.style1 .footer-top p :-moz-placeholder {
  color: #0a0a0a;
  opacity: 1;
}
.rs-footer.style1 .footer-bottom {
  padding: 20px 0;
  background: #000;
}
.rs-footer.style1 .footer-bottom .copy-right-menu li {
  display: inline-block;
  position: relative;
  padding: 0 19px 0 25px;
}
.rs-footer.style1 .footer-bottom .copy-right-menu li:before {
  display: block;
  content: "";
  position: absolute;
  font-size: 18px;
  background: #00a3e8;
  left: 0;
  top: 50%;
  width: 6px;
  height: 6px;
  transform: translateY(-50%);
  border-radius: 50%;
}
#scrollUp.paste-color i,
.home-paste-color
  .full-width-header
  .right_menu_togle
  .close-btn:hover
  .nav-close
  .line
  span,
.home-paste-color
  .full-width-header
  .rs-header
  .expand-btn-inner
  ul
  .humburger
  .nav-expander
  .bar
  span,
.home-paste-color .rs-carousel .owl-dots .owl-dot,
.home-paste-color .rs-carousel .owl-dots .owl-dot.active,
.home-paste-color .rs-carousel .owl-dots .owl-dot:hover,
.home-paste-color .search-modal,
.rs-footer.style1.footer-home5-style .footer-bottom .copy-right-menu li:before,
.rs-footer.style1.footer-home5-style .footer-top .footer-social li a i:hover,
.rs-footer.style1.footer-home5-style .footer-top .footer-title:after,
.rs-footer.style1.footer-home5-style .footer-top p input[type="submit"] {
  background: #4caf50;
}
.rs-footer .bg-wrap {
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 70px 0 0;
}
.rs-footer .bg-wrap .newslatter-wrap.extra-pt {
  padding-top: 281px;
}
.rs-footer .bg-wrap .newslatter-wrap .footer-newsletter {
  background: #fff;
  border-radius: 0 70px 0 0;
  padding: 78px 0 90px;
  box-shadow: 0 6px 30px 0 rgba(0, 0, 0, 0.08);
  position: relative;
}
.rs-footer .bg-wrap .newslatter-wrap .footer-newsletter.fly-up {
  position: absolute;
  content: "";
  top: -185.5px;
  width: 100%;
  z-index: 1;
  left: 0;
}
.rs-footer .bg-wrap .newslatter-wrap .footer-newsletter .pattern.img1 {
  bottom: 0;
  left: 50px;
}
.rs-footer .bg-wrap .newslatter-wrap .footer-newsletter .pattern.img2 {
  top: 0;
  right: 50px;
}
.rs-footer .bg-wrap .newslatter-wrap .footer-newsletter .subscribe-form {
  max-width: 450px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}
.rs-footer .bg-wrap .newslatter-wrap .footer-newsletter .subscribe-form input {
  width: 100%;
  background: #f5f5f5;
  border-radius: 30px;
  border: none;
  outline: 0;
  padding: 20px 25px;
  height: 65px;
}
.rs-footer
  .bg-wrap
  .newslatter-wrap
  .footer-newsletter
  .subscribe-form
  .readon2 {
  position: absolute;
  content: "";
  padding-right: 25px;
  padding-left: 25px;
  right: 7px;
  top: 7px;
  border: none;
  outline: 0;
  text-transform: capitalize;
  font-weight: 500;
}
.rs-footer
  .bg-wrap
  .newslatter-wrap
  .footer-newsletter
  .subscribe-form
  ::-webkit-input-placeholder {
  color: #999;
}
.rs-footer
  .bg-wrap
  .newslatter-wrap
  .footer-newsletter
  .subscribe-form
  :-ms-input-placeholder {
  color: #999;
}
.rs-footer
  .bg-wrap
  .newslatter-wrap
  .footer-newsletter
  .subscribe-form
  ::placeholder {
  color: #999;
}
.rs-footer .bg-wrap .footer-content .about-widget .logo-area {
  margin-bottom: 26px;
}
.rs-footer .bg-wrap .footer-content .about-widget .logo-area a img {
  height: 40px;
}
.rs-footer .bg-wrap .footer-content .about-widget a {
  color: #e2e2e2;
}
.rs-footer .bg-wrap .footer-content .about-widget .footer-desc {
  color: #e2e2e2;
  margin-bottom: 12px;
}
.rs-footer .bg-wrap .footer-content .about-widget .social-links {
  margin-top: 20px;
}
.rs-footer .bg-wrap .footer-content .about-widget .social-links li {
  display: inline;
  margin-right: 10px;
}
.rs-footer .bg-wrap .footer-content .about-widget .social-links li a {
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 37px;
  border-radius: 100%;
  display: inline-block;
  background: #e94d65;
  font-size: 16px;
  color: #fff;
}
.rs-footer .bg-wrap .footer-content .about-widget .social-links li a:hover {
  background: #fff;
  color: #e94d65;
}
.rs-footer .bg-wrap .footer-content .widget .widget-title {
  color: #fff;
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  margin-bottom: 26px;
}
.rs-footer .bg-wrap .footer-content .widget ul li {
  margin-bottom: 10px;
}
.rs-footer .bg-wrap .footer-content .widget ul li a {
  color: #e2e2e2;
  display: inline-block;
}
.rs-footer .bg-wrap .footer-content .widget ul li a:hover {
  color: #e94d65;
  margin-left: 5px;
}
.rs-footer .bg-wrap .footer-bottom {
  padding: 23px 0;
  border-top: 1px solid #13487a;
}
.rs-footer .bg-wrap .footer-bottom .copyright {
  margin: 0;
  color: #dedede;
  font-size: 14px;
}
.rs-footer .pattern-right {
  position: absolute;
  bottom: 0;
  right: 0;
}
.extra-pb {
  padding-bottom: 306px;
}
#scrollUp {
  text-align: center;
  bottom: 40px;
  cursor: pointer;
  display: none;
  right: 20px;
  z-index: 999;
  border-radius: 50px 50px 4px 4px;
}
#scrollUp i {
  height: 40px;
  font-size: 24px;
  width: 42px;
  border-radius: 0;
  color: #fff;
  transition: 0.3s;
  background: #00a3e8;
  margin-left: 2px;
  box-shadow: 0 0 2px #54595f;
}
.text-center .banner-border-line {
  margin: 0 auto 26px;
}
.home-blue-color .search-modal .search-block form button,
.home-orange-color .search-modal .search-block form button,
.home-paste-color .search-modal .search-block form button {
  background: unset;
  border: none;
  padding: 0 20px;
  right: 0;
  top: 0;
}
.home-paste-color .gridFilter button.active,
.home-paste-color .gridFilter button:hover {
  background: #4caf50 !important;
}
.home-6 a {
  color: #043d72;
  text-decoration: none;
}
.home-6 .readon.submit.paste-btn {
  border-radius: 30px;
  padding-top: 14px;
  padding-bottom: 14px;
}
.home-6 #scrollUp i,
.home-6
  .full-width-header
  .right_menu_togle
  .canvas-contact
  .social
  li
  a
  i:hover,
.home-6
  .full-width-header
  .rs-header
  .expand-btn-inner
  ul
  .humburger
  .nav-expander
  .bar
  span,
.home-6 .full-width-header .rs-header.style2 .topbar-area,
.home-6 .gridFilter button.active,
.home-6 .gridFilter button:hover,
.home-6 .readon.submit.paste-btn,
.home-6
  .rs-project.style1
  .project-item
  .project-content
  .project-inner
  .p-icon {
  background: #e94d65;
}
.home-6
  .full-width-header
  .rs-header
  .topbar-area.style1
  .toolbar-sl-share
  ul
  li
  a:hover {
  opacity: 0.85;
  color: #fff;
}
.pl-30 {
  padding-left: 30px;
}
.pr-30 {
  padding-right: 30px;
}
#pre-load {
  background-color: #fff;
  height: 100%;
  width: 100%;
  margin-top: 0;
  top: 0;
  z-index: 9999;
}
.loader .loader-container {
  width: 100px;
  height: 100px;
  border: 5px solid #ebebec;
  border-radius: 50%;
}
.loader .loader-container:before {
  content: "";
  display: block;
  width: 100px;
  height: 100px;
  border-top: 4px solid #f00c46;
  border-radius: 50%;
  animation: 1.8s ease-in-out infinite loaderspin;
  -webkit-animation: 1.8s ease-in-out infinite loaderspin;
}
.loader .loader-icon {
  width: 80px;
  text-align: center;
}
.loader .loader-icon img {
  animation: 0.9s infinite alternate loaderpulse;
  width: 30px;
}
.woocommerce .quantity input[type="number"]::-webkit-input-placeholder {
  color: #363636 !important;
  opacity: 1;
}
.woocommerce .quantity input[type="number"]::-moz-placeholder {
  color: #363636 !important;
  opacity: 1;
}
.woocommerce .quantity input[type="number"]:-ms-input-placeholder {
  color: #363636 !important;
  opacity: 1;
}
.woocommerce .quantity input[type="number"]:-moz-placeholder {
  color: #363636 !important;
  opacity: 1;
}
@keyframes loaderspin {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
@-webkit-keyframes loaderspin {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
@keyframes loaderpulse {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}
@-webkit-keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}
.move-y {
  animation: 2s infinite alternate move-y;
  -webkit-animation: 2s infinite alternate move-y;
}
@keyframes move-y {
  0% {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(0, 10px, 0);
    -webkit-transform: translate3d(0, 10px, 0);
  }
}
.scale {
  animation: 2s infinite alternate scale;
}
@-webkit-keyframes scale {
  0% {
    transform: scale(0.8);
    -webkit-transform: scale(0.8);
  }
  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
.dance2 {
  -webkit-animation: 4s infinite alternate dance2;
}
@keyframes dance2 {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
  }
  50% {
    -webkit-transform: translate3d(15px, -15px, 0);
  }
  100% {
    -webkit-transform: translate3d(0, -15px, 15px);
  }
}
.up-down {
  animation: 7s infinite alternate up-down;
  -webkit-animation: 7s infinite alternate up-down;
}
@keyframes up-down {
  0% {
    transform: translateY(20px);
    -webkit-transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
    -webkit-transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
    -webkit-transform: translateY(0);
  }
}
@-webkit-keyframes up-down {
  0% {
    transform: translateY(30px);
    -webkit-transform: translateY(0);
  }
  50% {
    transform: translateY(-50px);
    -webkit-transform: translateY(-50px);
  }
  100% {
    transform: translateY(0);
    -webkit-transform: translateY(0);
  }
}
.spine {
  animation: 8s linear infinite spine;
}
.horizontal,
.vertical {
  animation-duration: 12s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
@keyframes spine {
  0% {
    transform: rotate(0);
    -webkit-transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spine {
  0% {
    transform: rotate(0);
    -webkit-transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}
.horizontal {
  animation-name: horizontal;
}
.horizontal.new-style,
.vertical.new-style {
  animation-duration: 15s;
}
@keyframes horizontal {
  0%,
  100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(100px);
  }
}
.vertical {
  animation-name: vertical;
}
@keyframes vertical {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(100px);
  }
}
.fadeinup {
  animation-duration: 2s;
  animation-name: fadeInUp;
}
.fadeinright,
.fadeinup.new {
  animation-duration: 3s;
}
.fadeinright {
  animation-name: fadeInRight;
}
@keyframes fadeinup {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}
.rotate_Y {
  animation: 5s infinite mymove;
}
@keyframes mymove {
  0% {
    transform: rotateY(-50deg);
  }
  100% {
    transform: rotateY(50deg);
  }
}
.rs-contact.main-home .contact-icons-style.box-address .contact-item:hover,
.rs-iconbox-area .box-inner .box-item:hover .dance_hover,
.rs-services.style4 .services-item:hover .dance_hover,
.rs-services.style6 .services-box-area .services-item:hover .dance_hover {
  animation-name: dance_hover;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}
@keyframes dance_hover {
  16.65% {
    transform: translateY(8px);
  }
  33.3% {
    transform: translateY(-6px);
  }
  49.95% {
    transform: translateY(4px);
  }
  66.6% {
    transform: translateY(-2px);
  }
  83.25% {
    transform: translateY(1px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes start-draw {
  to {
    height: 95%;
  }
}
@keyframes rotate-anim {
  from {
    -webkit-transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}
@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}
.enquirebtn {
  background: #00a3e8;
  padding: 10px;
  color: #fff;
  font-weight: 600;
  border-radius: 20px;
  margin-top: 10px;
}
.enquirebtn:hover {
  background: #000;
  color: #fff !important;
  /* padding: 10px; */
  /* border: 2px solid #fff; */
  /* border-radius: 20px; */
}
/* .rs-slider .wave,
.rs-slider .wave:after,
.rs-slider .wave:before {
  background-image: url(../../public/images/wave.png);
  background-size: contain;
  position: absolute;
  width: 100%;
} */
.gray-bg {
  background: #ebebeb;
}
.icon-seven,
.icon-six {
  background-position: center center;
}
.choose-area,
.icon-seven,
.icon-six {
  background-repeat: no-repeat;
}
.rs-slider{
  height: 100vh;
  background: #003049;
  /* background-image: url('../../public/newImages/4734691_2202758.jpg'); */
  background-size: cover;
  /* background: rgba(0,0,0,0.6); */
}
/* .cover{
  background: rgba(0,0,0,0.6);
  height: 100vh;
  width: 150%;
  background-size: cover;
  background: transparent
} */
.test{
  position: relative;
  height: 100vh;
  width: 100vw;
  /* background: rgba(0,0,0,0.7); */
}
.rs-slider .wave {
  bottom: 12%;
  -webkit-animation: 5s linear infinite move-img;
  animation: 5s linear infinite move-img;
  height: 40px;
}
.rs-slider .wave:before {
  content: "";
  bottom: 0;
  opacity: 0.4;
  -webkit-animation: 5s linear infinite move-img-reverse;
  animation: 5s linear infinite move-img-reverse;
  height: 40px;
}
.rs-slider .wave:after {
  content: "";
  bottom: 0;
  opacity: 0.4;
  -webkit-animation: 20s linear infinite move-img;
  animation: 20s linear infinite move-img;
  height: 45px;
}
.rat-sib,
.rat-squar1 {
  -webkit-animation: 3s linear infinite hom-suar;
}
@-webkit-keyframes move-img {
  0% {
    background-position: 0;
  }
  to {
    background-position: 1360px;
  }
}
@keyframes move-img {
  0% {
    background-position: 0;
  }
  to {
    background-position: 1360px;
  }
}
@-webkit-keyframes move-img-reverse {
  0% {
    background-position: 1360px;
  }
  to {
    background-position: 0;
  }
}
@keyframes move-img-reverse {
  0% {
    background-position: 1360px;
  }
  to {
    background-position: 0;
  }
}
#particles-js {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.social {
  top: 30%;
  z-index: 1;
  width: 100px;
}
.social ul .whatsap {
  display: block !important;
  margin: 10px !important;
  width: 310px !important;
  padding: 15px !important;
  border-radius: 0 30px 30px 0 !important;
  transition: 1.5s !important;

}
.icon-seven,
.icon-six,
.rat-ract,
.rat-round,
.rat-sib,
.rat-squar1,
.rat-squar2 {
  position: absolute;
}
.social ul {
  padding: 0;
  transform: translate(-270px, 0);
}
.social ul li {
  display: block;
  margin: 10px;
  width: 300px;
  padding: 10px;
  border-radius: 0 30px 30px 0;
  transition: 1.5s;
}
.social ul .facebook,
.social ul .facebook:hover {
  background: #3b5998;
}
.social ul .insta,
.social ul .insta:hover {
  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
}
.social ul .linke,
.social ul .linke:hover {
  background: #0077b5;
}
.social ul .twit,
.social ul .twit:hover {
  background: #00acee;
}
.social ul .youtube,
.social ul .youtube:hover {
  background-color: #0062cc;
}
.social ul .whatsap,
.social ul .whatsap:hover {
  background-color: #25D366  ;
}

.social ul li:hover {
  transform: translate(110px, 0);
  transition: 1.5s;
}
.social ul li:hover i {
  color: #00a3e8;
  background: #fff;
  transform: rotate(360deg);
  transition: 1.5s;
}
.social ul li i {
  margin-left: 10px;
  color: #000;
  background: #fff;
  padding: 7px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  transform: rotate(0);
  text-align: center;
  font-size: 18px;
}
.rat-sib {
  top: 5%;
  left: 15%;
  animation: 3s linear infinite hom-suar;
}
.rat-round {
  bottom: 10%;
  left: 5%;
  -webkit-animation: 3s linear infinite hom-round;
  animation: 3s linear infinite hom-round;
}
.rat-ract {
  top: 50%;
  right: 9%;
  -webkit-animation: 80s linear infinite hom-ract;
  animation: 80s linear infinite hom-ract;
}
.rat-squar1 {
  animation: 3s linear infinite hom-suar;
  top: 8%;
  right: 10%;
}
.rat-squar2 {
  -webkit-animation: 3s linear infinite hom-suar;
  animation: 3s linear infinite hom-suar;
  bottom: 15%;
  right: 20%;
}
@-webkit-keyframes hom-suar {
  0%,
  to {
    transform: translateY(0);
  }
  50% {
    transform: translateY(20px);
  }
}
@keyframes hom-suar {
  0%,
  to {
    transform: translateY(0);
  }
  50% {
    transform: translateY(20px);
  }
}
@-webkit-keyframes hom-ract {
  0%,
  to {
    transform: translateY(0) translatex(100px);
  }
  50% {
    transform: translateY(70px) translatex(-40px);
  }
}
@keyframes hom-ract {
  0%,
  to {
    transform: translateY(0) translatex(100px);
  }
  50% {
    transform: translateY(70px) translatex(-40px);
  }
}
@-webkit-keyframes hom-round {
  0%,
  to {
    transform: translate(0);
  }
  50% {
    transform: translate(20px) scale(2);
  }
}
@keyframes hom-round {
  0%,
  to {
    transform: translate(0);
  }
  50% {
    transform: translate(20px) scale(2);
  }
}
@-webkit-keyframes hom-sib {
  0%,
  to {
    transform: translate(-50px);
  }
  50% {
    transform: translateX(100px) translateY(-300px);
  }
}
@keyframes hom-sib {
  0%,
  to {
    transform: translate(-50px);
  }
  50% {
    transform: translateX(100px) translateY(-300px);
  }
}
.client23 {
  padding: 26px 0 109px;
}
.client23 .counter-area {
  text-align: center;
  margin-top: 60px;
}
.client23 img {
  border: 2px solid #00a3e8;
  border-radius: 50%;
  box-shadow: -2px -4px 5px #eee;
}
.icon-seven {
  top: 22%;
  width: 207px;
  height: 45px;
  -webkit-transition: 1.6s;
  -ms-transition: 1.6s;
  transition: 1.6s;
  -webkit-transform: translateX(50px);
  -ms-transform: translateX(50px);
  transform: translateX(50px);
  -webkit-animation: 10s linear infinite animateCloudtwo;
  -moz-animation: 10s linear infinite animateCloudtwo;
  animation: 10s linear infinite animateCloudtwo;
}
.icon-six {
  top: 32%;
  width: 141px;
  height: 34px;
  -webkit-transition: 0.9s;
  -ms-transition: 0.9s;
  transition: 0.9s;
  -webkit-transform: translateX(50px);
  -ms-transform: translateX(50px);
  transform: translateX(50px);
  -webkit-animation: 10s linear infinite animateCloud;
  -moz-animation: 10s linear infinite animateCloud;
  animation: 10s linear infinite animateCloud;
}
@-webkit-keyframes animateCloud {
  0% {
    margin-left: -100px;
  }
  100% {
    margin-left: 120%;
  }
}
@-moz-keyframes animateCloud {
  0% {
    margin-left: -100px;
  }
  100% {
    margin-left: 120%;
  }
}
@keyframes animateCloud {
  0% {
    margin-left: -100px;
  }
  100% {
    margin-left: 120%;
  }
}
@-webkit-keyframes animateCloudtwo {
  0% {
    right: -140px;
  }
  100% {
    right: 120%;
  }
}
@-moz-keyframes animateCloudtwo {
  0% {
    right: -140px;
  }
  100% {
    right: 120%;
  }
}
@keyframes animateCloudtwo {
  0% {
    right: -140px;
  }
  100% {
    right: 120%;
  }
}
.client23 img:hover {
  border: 2px solid #e1de19;
  border-radius: 50%;
  box-shadow: 0 0 9px 5px #e1de19;
}
.full-width-header .rs-header .menu-area.sticky .enquirebtn:hover {
  background: #fff;
  border: 2px solid #00a3e8;
  color: #00a3e8 !important;
}
.about-circle-shape {
  position: absolute;
  top: 50%;
  left: 5%;
  -webkit-transform: translateY(-50%) translateX(-5%);
  transform: translateY(-50%) translateX(-5%);
}
.about-circle-shape img,
.blog-details-desc .article-content .article-quote .quote-shape img,
.blog-shape-1 img {
  -webkit-animation-name: rotateme;
  animation-name: rotateme;
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}
@-webkit-keyframes rotateme {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotateme {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.choose-area {
  position: relative;
  overflow: hidden;
  background-image: url(../../public/images/choose.jpg);
  background-size: cover;
  border-radius: 100px 100px 0 0;
}
.single-choose-card .choose-image {
  margin-bottom: 25px;
  position: relative;
  display: inline-block;
}
.single-choose-card .choose-image img {
  animation: 1.5s ease-in-out infinite both heartbeat;
}
@keyframes heartbeat {
  from {
    transform: scale(1);
    transform-origin: center center;
    animation-timing-function: ease-out;
  }
  10% {
    transform: scale(0.91);
    animation-timing-function: ease-in;
  }
  17% {
    transform: scale(0.98);
    animation-timing-function: ease-out;
  }
  33% {
    transform: scale(0.87);
    animation-timing-function: ease-in;
  }
  45% {
    transform: scale(1);
    animation-timing-function: ease-out;
  }
}
.single-choose-card .choose-content h3 {
  font-size: 22px;
  margin-bottom: 15px;
}
.choose-content h3 a:hover,
.fa-eye,
.single-blog-card .blog-content h3 a {
  color: #000;
}
.choose-shape-1 {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 50px;
}
.choose-shape-2 {
  position: absolute;
  left: 200px;
  margin: auto;
  top: -300px;
}
.rs-contact.main-home .contact-wrap .from-control {
  padding: 17px;
  color: #fff;
  border: 1px solid #0d0d0d;
  background-color: #0d0d0d;
  width: 100%;
  max-width: 100%;
  opacity: 1;
}
.rs-contact.main-home .contact-wrap ::-webkit-input-placeholder {
  color: #fff;
  opacity: 1;
}
.rs-contact.main-home .contact-wrap ::-moz-placeholder {
  color: #fff;
  opacity: 1;
}
.rs-contact.main-home .contact-wrap :-ms-input-placeholder {
  color: #fff;
  opacity: 1;
}
.rs-contact.main-home .contact-wrap :-moz-placeholder {
  color: #fff;
  opacity: 1;
}
.rs-contact.main-home .contact-map iframe {
  float: left;
  width: 100%;
  height: 400px;
  border: none;
}
.rs-contact.main-home .contact-icons-style .contact-item {
  padding: 30px 10px;
  margin: 0 -5px 0 0;
}
.rs-contact.main-home .contact-icons-style .contact-item .contact-icon img {
  max-width: unset;
  width: 30px;
  height: 30px;
  padding: 5px 5px 0 0;
}
.rs-contact.main-home .contact-icons-style .contact-item .content-text {
  padding: 0 0 0 15px;
}
.rs-contact.main-home .contact-icons-style .contact-item .content-text .title,
.rs-contact.main-home.office-modify1 .contact-box .content-text .title {
  font-size: 20px;
  font-weight: 600;
  line-height: 38px;
  margin: 0 0 10px;
}
.rs-contact.main-home .contact-icons-style .contact-item .content-text a {
  color: #c5c2c2;
}
.rs-contact.main-home
  .contact-icons-style
  .contact-item
  .content-text
  .services-txt {
  margin: 0-9px 0 -9px;
  color: #c5c2c2;
}
.rs-contact.main-home .contact-icons-style.box-address .contact-item {
  background: #fff;
  box-shadow: 0 0 45px 22px #eee;
  border-radius: 5px;
  margin: 0;
  text-align: center;
  min-height: 228px;
}
.rs-contact.main-home
  .contact-icons-style.box-address
  .contact-item
  .content-text
  a {
  color: #444;
  line-height: 1.8;
  font-weight: 600;
}
.rs-contact.main-home
  .contact-icons-style.box-address
  .contact-item
  .content-text
  .services-txt {
  color: #444;
}
.rs-contact.main-home.office-modify1 .contact-map iframe {
  height: 761px;
}
.rs-contact.main-home.office-modify1 .contact-section {
  background-color: #ebebeb;
  padding: 100px 80px 121px;
}
.rs-contact.main-home.office-modify1
  .contact-section
  .contact-wrap
  .from-control {
  color: #000;
  border-style: solid;
  border-color: #fff;
  background-color: #fff;
}
.rs-contact.main-home.office-modify1
  .contact-section
  .contact-wrap
  ::-webkit-input-placeholder {
  color: #454545;
  opacity: 0.55;
}
.rs-contact.main-home.office-modify1
  .contact-section
  .contact-wrap
  ::-moz-placeholder {
  color: #454545;
  opacity: 0.55;
}
.rs-contact.main-home.office-modify1
  .contact-section
  .contact-wrap
  :-ms-input-placeholder {
  color: #454545;
  opacity: 1;
}
.rs-contact.main-home.office-modify1
  .contact-section
  .contact-wrap
  :-moz-placeholder {
  color: #454545;
  opacity: 0.55;
}
.rs-contact.main-home.office-modify1 .contact-section.contact-style2 {
  padding: 90px 80px 100px;
}
.rs-contact.main-home.office-modify1 .contact-box {
  padding: 35px;
  background-color: #ebebeb;
  margin: 0 -4px 0 0;
}
.rs-contact .contact-icon i {
  color: #00a3e8;
  font-size: 20px;
  margin-bottom: 10px;
}
.rs-contact.faq-style .contact-item .content-text .title a,
.rs-contact.main-home.office-modify1 .contact-box .content-text .title a {
  color: #14171c;
}
.rs-contact.contact-style2 .contact-address .address-item .address-text a,
.rs-contact.main-home.office-modify1 .contact-box .content-text a {
  color: #444;
  font-weight: 500;
}
.rs-contact.main-home.office-modify1 .contact-box .content-text .services-txt {
  margin: 0;
  color: #444;
  font-weight: 500;
}
.rs-contact.main-home.home5-contact-style .contact-wrap .from-control {
  color: #0a0a0a;
  border-color: #f1f1f1;
  background-color: #f1f1f1;
}
.rs-contact.main-home.home5-contact-style ::-webkit-input-placeholder {
  color: #454545;
  opacity: 0.4;
}
.rs-contact.main-home.home5-contact-style ::-moz-placeholder {
  color: #454545;
  opacity: 0.4;
}
.rs-contact.main-home.home5-contact-style :-ms-input-placeholder {
  color: #454545;
  opacity: 0.4;
}
.rs-contact.main-home.home5-contact-style :-moz-placeholder {
  color: #454545;
  opacity: 0.4;
}
.rs-contact.contact-style2 .contact-address {
  background-color: #fff;
  padding: 70px 50px 60px 60px;
}
.rs-contact.contact-style2 .contact-address .address-item {
  display: flex;
  position: relative;
  align-items: center;
  margin-bottom: 30px;
}
.rs-contact.contact-style2 .contact-address .address-item .address-icon img {
  width: 35px;
  height: 35px;
  max-width: unset;
}
.rs-contact.contact-style2 .contact-address .address-item .address-text {
  padding-left: 25px;
  color: #444;
  font-weight: 500;
}
.rs-contact.contact-style2 .contact-map {
  overflow: hidden;
  padding-right: 50px;
}
.rs-contact.contact-style2 .contact-map iframe {
  float: left;
  width: 100%;
  height: 506px;
  border: none;
}
.rs-contact.contact-style2 .contact-wrap .from-control {
  padding: 10px;
  color: #000;
  border: 1px solid #00a3e8;
  background-color: #fff;
  width: 100%;
  max-width: 100%;
  opacity: 1;
  box-shadow: 0 2px 20px rgb(0 0 0 / 8%);
  border-radius: 20px;
}
.rs-contact.contact-style2 .contact-wrap ::-webkit-input-placeholder {
  color: #454545;
  opacity: 0.55;
}
.rs-contact.contact-style2 .contact-wrap ::-moz-placeholder {
  color: #454545;
  opacity: 0.55;
}
.rs-contact.contact-style2 .contact-wrap :-ms-input-placeholder {
  color: #454545;
  opacity: 1;
}
.rs-contact.contact-style2 .contact-wrap :-moz-placeholder {
  color: #454545;
  opacity: 0.55;
}
.rs-contact.faq-style .contact-item {
  padding: 35px;
  background-color: #fff;
}
.rs-contact.faq-style .contact-item .contact-icon img {
  width: 64px;
}
.rs-contact.faq-style .contact-item .content-text .title {
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  margin: 25px 0 5px;
}
.rs-contact.faq-style .contact-item .content-text .services-txt {
  color: #333;
  margin: 0;
}
.rs-contact.home3-contact-style .contact-wrap {
  padding: 60px 60px 83px;
  background: #fff;
}
.rs-contact.home3-contact-style .contact-wrap .from-control {
  padding: 20px;
  border: 0 solid #fff;
  background-color: #f9f9f9;
  width: 100%;
  max-width: 100%;
  opacity: 1;
}
.rs-contact.home3-contact-style .contact-wrap ::-webkit-input-placeholder {
  color: #454545;
  opacity: 0.55;
}
.rs-contact.home3-contact-style .contact-wrap ::-moz-placeholder {
  color: #454545;
  opacity: 0.55;
}
.rs-contact.home3-contact-style .contact-wrap :-ms-input-placeholder {
  color: #454545;
  opacity: 0.55;
}
.rs-contact.home3-contact-style .contact-wrap :-moz-placeholder {
  color: #454545;
  opacity: 0.55;
}
.blog-area {
  overflow: hidden;
  position: relative;
}
.blog-card .blog-image {
  overflow: hidden;
  border-radius: 15px;
}
.blog-card .blog-image a img,
.blog-details-desc .article-image img {
  border-radius: 15px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}
.blog-card .blog-content .date {
  display: inline-block;
  font-size: var(--font-size);
  font-weight: 500;
  font-family: var(--heading-font-family);
  padding: 12px 25px;
  border-radius: 50px;
  border: 1px solid var(--white-color);
  -webkit-box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
  margin-bottom: 25px;
  color: var(--optional-color);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}
.blog-card .blog-content h3 {
  font-size: 22px;
  line-height: 1.5;
  margin-bottom: 10px;
  font-weight: 700;
}
.blog-card .blog-content h3 a {
  color: var(--black-color);
}
.blog-card .blog-content .blog-btn {
  background: var(--btn-gradient-color);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  font-family: var(--heading-font-family);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  position: relative;
  margin-top: 20px;
}
.blog-card .blog-content .blog-btn::before {
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  width: 20px;
  height: 1.5px;
  background: var(--btn-gradient-color);
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-radius: 5px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}
.blog-card:hover .blog-image a img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-filter: blur(2px);
  filter: blur(2px);
}
.blog-card:hover .blog-content .date {
  background: radial-gradient(
    circle,
    #a66bff,
    #c666ef,
    #dd62df,
    #ee61cf,
    #fb64bf
  );
  color: var(--white-color);
}
.blog-card:hover .blog-content h3 a,
.blog-details-desc .article-leave-comment .form-cookies-consent a,
.blog-grid-sorting form button:focus,
.blog-grid-sorting form button:hover,
.single-blog-card:hover .blog-content h3 a {
  color: var(--main-color);
}
.blog-card:hover .blog-content .blog-btn::before,
.single-blog-card:hover .blog-content .blog-btn::before {
  opacity: 1;
  visibility: visible;
}
.blog-slides.owl-theme .owl-nav {
  margin-top: 0;
}
.blog-slides.owl-theme .owl-nav [class*="owl-"] {
  margin: 0 10px;
  padding: 0;
  background: 0 0;
  color: #a66bff;
  border: 2px solid #a66bff;
  border-radius: 50%;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  display: inline-block;
  width: 55px;
  height: 55px;
  line-height: 55px;
  text-align: center;
  font-size: 35px;
  position: absolute;
  left: -80px;
  top: 45%;
  -webkit-transform: translateY(-45%);
  transform: translateY(-45%);
}
.blog-slides.owl-theme .owl-nav [class*="owl-"] i {
  position: relative;
  left: -2px;
  top: 2px;
}
.blog-slides.owl-theme .owl-nav [class*="owl-"].owl-next {
  left: auto;
  right: -80px;
}
.blog-slides.owl-theme .owl-nav [class*="owl-"].owl-next i {
  position: relative;
  left: 2px;
  top: 2px;
}
.blog-slides.owl-theme .owl-nav [class*="owl-"]:hover {
  color: var(--white-color);
  background-color: var(--main-color);
  border: 2px solid var(--main-color);
}
.blog-shape-1 {
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}
.blog-grid-sorting {
  margin-bottom: 45px;
  text-align: end;
}
.blog-grid-sorting label {
  display: inline-block;
  margin-bottom: 0;
  color: var(--paragraph-color);
  margin-right: 5px;
  font-weight: 600;
  font-size: 15px;
}
.blog-grid-sorting .nice-select {
  display: inline-block;
  width: 300px;
  background: #f2f1f3;
  border-color: #f2f1f3;
  color: #9b8dac;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  padding: 0 0 0 25px;
  height: 70px;
  line-height: 70px;
  font-weight: 400;
  border-radius: 15px;
}
.blog-grid-sorting .nice-select .list {
  background-color: var(--white-color);
  border-radius: 10px;
  -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  list-style-type: none;
  border: none;
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  padding-top: 10px;
  padding-bottom: 10px;
}
.blog-grid-sorting .nice-select .list .option {
  line-height: 38px;
  min-height: 38px;
  color: var(--black-color);
  position: relative;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  padding-left: 15px;
  padding-right: 25px;
  font-weight: 600;
}
.blog-grid-sorting .nice-select .list .option.selected.focus {
  color: var(--main-color);
  background-color: var(--white-color);
}
.blog-grid-sorting .nice-select .list .option:hover {
  background-color: var(--main-color);
  color: var(--white-color);
}
.blog-grid-sorting .nice-select:after {
  border-color: var(--black-color);
  height: 8px;
  width: 8px;
  margin-top: -5px;
  right: 20px;
}
.blog-grid-sorting .nice-select:hover {
  border-color: var(--main-color);
  background-color: transparent;
}
.blog-grid-sorting form {
  position: relative;
  max-width: 450px;
}
.blog-grid-sorting form .search-field {
  height: 70px;
  color: #9b8dac;
  border: 1px solid #f2f1f3;
  display: block;
  width: 100%;
  border-radius: 15px;
  padding: 15px 20px 15px 70px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-size: 15px;
  font-weight: 400;
  outline: 0;
  background: #f2f1f3;
}
.blog-grid-sorting form .search-field::-webkit-input-placeholder {
  color: #9b8dac;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}
.blog-grid-sorting form .search-field:-ms-input-placeholder {
  color: #9b8dac;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}
.blog-grid-sorting form .search-field::-ms-input-placeholder {
  color: #9b8dac;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}
.blog-grid-sorting form .search-field::placeholder {
  color: #9b8dac;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}
.blog-grid-sorting form .search-field:focus::-webkit-input-placeholder {
  color: transparent;
}
.blog-grid-sorting form .search-field:focus:-ms-input-placeholder {
  color: transparent;
}
.blog-grid-sorting form .search-field:focus::-ms-input-placeholder {
  color: transparent;
}
.blog-grid-sorting form .search-field:focus::placeholder {
  color: transparent;
}
.blog-grid-sorting form button {
  border: none;
  background-color: transparent;
  color: var(--black-color);
  position: absolute;
  left: 32px;
  padding: 0;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  top: 22.8px;
  font-size: 25px;
  line-height: 0;
}
.single-blog-card .blog-image {
  position: relative;
  overflow: hidden;
  border-radius: 15px;
}
.single-blog-card .blog-image a img {
  border-radius: 15px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  height: 230px;
}
.single-blog-card .blog-image .date {
  background: radial-gradient(
    circle,
    #a66bff,
    #c666ef,
    #dd62df,
    #ee61cf,
    #fb64bf
  );
  display: inline-block;
  font-size: 15px;
  font-weight: 500;
  font-family: var(--heading-font-family);
  padding: 4px 12px;
  border-radius: 50px;
  color: var(--white-color);
  position: absolute;
  left: 15px;
  bottom: 15px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}
.single-blog-card .blog-content {
  margin-top: 25px;
}
.single-blog-card .blog-content h3 {
  font-size: 22px;
  line-height: 1.5;
  margin-bottom: 10px;
  font-weight: 700;
  height: 100px;
}
.blog-details-desc .article-leave-comment .form-cookies-consent,
.mb-20,
.single-blog-card .blog-content p {
  margin-bottom: 20px;
}
.single-blog-card .blog-content .blog-btn {
  -webkit-transition: var(--transition);
  transition: var(--transition);
}
.single-blog-card .blog-content .blog-btn::before {
  content: "";
  background: var(--optional-color);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}
.single-blog-card:hover .blog-image .date {
  -webkit-animation: 1s bounce;
  animation: 1s bounce;
}
.blog-details-desc .article-image {
  border-radius: 15px;
  overflow: hidden;
  margin-bottom: 30px;
  position: relative;
}
.blog-details-desc .article-image .video-btn {
  display: inline-block;
  width: 80px;
  height: 80px;
  line-height: 90px;
  background-color: var(--black-color);
  border-radius: 50%;
  color: var(--optional-color);
  position: absolute;
  z-index: 1;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  font-size: 45px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border: 2px solid #fb64bf;
}
.blog-details-desc .article-image .video-btn i {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  background: radial-gradient(
    circle,
    #a66bff,
    #c666ef,
    #dd62df,
    #ee61cf,
    #fb64bf
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.blog-details-desc .article-image .video-btn::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  border: 2px solid #fb64bf;
  -webkit-animation: 2s linear infinite ripple;
  animation: 2s linear infinite ripple;
}
.blog-details-desc .article-image .video-btn:hover {
  background-color: var(--optional-color);
}
.blog-details-desc .article-image .video-btn:hover i {
  background: var(--btn-gradient-color);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.blog-details-desc .article-content .entry-list {
  padding: 0;
  margin-bottom: 18px;
}
.blog-details-desc .article-content .entry-list li {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  list-style-type: none;
  display: inline-block;
  margin-right: 20px;
  position: relative;
  padding-left: 25px;
}
.blog-details-desc .article-content .entry-list li::before {
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  width: 15px;
  height: 2.5px;
  background: #01a3e8;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-radius: 50px;
}
.blog-details-desc .article-content .entry-list li a {
  color: var(--optional-color);
  font-weight: 500;
}
.blog-details-desc .article-content h3 {
  font-size: 37px;
  margin-bottom: 15px;
  line-height: 52px;
}
.blog-details-desc .article-content .list {
  padding: 0;
  margin-top: 30px;
  margin-bottom: 0;
}
.blog-details-desc .article-content .list li {
  list-style-type: none;
  margin-bottom: 20px;
  color: var(--paragraph-color);
  position: relative;
  padding-left: 25px;
  font-weight: 500;
}
.blog-details-desc .article-content .list li:first-child {
  padding-left: 0;
}
.blog-details-desc .article-content .list li i {
  position: absolute;
  left: 0;
  top: 2.8px;
  font-size: 18px;
  color: #01a3e8;
}
.blog-details-desc .article-content .list li h4 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 0;
}
.blog-details-desc .article-content .block-item {
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  margin-top: 30px;
  margin-bottom: 30px;
}
.blog-details-desc .article-content .block-item img {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border-radius: 15px;
}
.blog-details-desc .article-content .article-quote {
  background-color: #f5f5f5;
  padding: 30px 30px 30px 90px;
  border-radius: 15px;
  position: relative;
  z-index: 1;
  margin-top: 30px;
  margin-bottom: 30px;
}
.blog-details-desc .article-content .article-quote i {
  color: #cfc3e2;
  font-size: 65px;
  position: absolute;
  left: 45px;
  top: 10px;
  z-index: -1;
  -webkit-animation: 2s linear infinite moveleftbounce;
  animation: 2s linear infinite moveleftbounce;
}
.blog-details-desc .article-content .article-quote p {
  font-size: 17px;
  font-weight: 500;
  color: #000;
  margin-bottom: 0;
}
.blog-details-desc .article-content .article-quote .quote-shape {
  position: absolute;
  right: -45px;
  bottom: -45px;
  max-width: 100px;
}
.blog-details-desc .article-share {
  background-color: #f2f1f3;
  padding: 30px;
  border-radius: 10px;
  margin-top: 30px;
}
.blog-details-desc .article-share .share-content h4 {
  margin-bottom: 0;
  font-size: var(--font-size);
  font-weight: 700;
}
.blog-details-desc .article-share .share-social {
  padding: 0;
  margin-bottom: 0;
}
.blog-details-desc .article-share .share-social li {
  display: inline-block;
  list-style-type: none;
  margin-right: 5px;
}
.blog-details-desc .article-share .share-social li a i {
  display: inline-block;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  height: 35px;
  width: 35px;
  line-height: 35px;
  text-align: center;
  border-radius: 50px;
  background-color: #fff;
  color: #01a3e8;
  font-size: 18px;
}
.blog-details-desc .article-share .share-social li a i:hover {
  color: #fff;
  background: #000;
}
.blog-details-desc .article-comments {
  margin-top: 35px;
}
.blog-details-desc .article-comments h3,
.blog-details-desc .article-leave-comment h3 {
  font-size: 22px;
  margin-bottom: 35px;
}
.blog-details-desc .article-comments .comments-list {
  position: relative;
  padding-left: 150px;
  margin-bottom: 35px;
}
.blog-details-desc .article-comments .comments-list img {
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 20px;
  max-width: 128px;
}
.blog-details-desc .article-comments .comments-list h5 {
  font-size: 18px;
  margin-bottom: 12px;
  color: var(--optional-color);
  font-weight: 600;
}
.blog-details-desc .article-comments .comments-list h5 span {
  font-size: var(--font-size);
  font-weight: 400;
  color: #9b8dac;
}
.blog-details-desc .article-comments .comments-list p {
  margin-bottom: 12px;
}
.blog-details-desc .article-comments .comments-list .reply-btn {
  font-size: var(--font-size);
  font-weight: 600;
  color: #00a3e8;
}
.blog-details-desc .article-comments .comments-list.children {
  margin-left: 50px;
}
.blog-details-desc .article-leave-comment {
  border-top: 1px solid #f2f1f3;
  margin-top: 45px;
  padding-top: 35px;
}
.blog-details-desc .article-leave-comment .form-group label {
  display: block;
  margin-bottom: 10px;
  color: var(--black-color);
  font-weight: 500;
  font-size: 15px;
}
.blog-details-desc .article-leave-comment .form-group .form-control {
  height: 55px;
  padding: 15px 20px;
  line-height: initial;
  color: #9b8dac;
  background-color: var(--white-color);
  border: 1px solid var(--white-color);
  -webkit-box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
  box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
  border-radius: 15px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-size: var(--font-size);
  font-weight: 400;
  outline: 0;
  font-family: var(--heading-font-family);
}
.blog-details-desc
  .article-leave-comment
  .form-group
  .form-control::-webkit-input-placeholder {
  color: #9b8dac;
}
.blog-details-desc
  .article-leave-comment
  .form-group
  .form-control:-ms-input-placeholder {
  color: #9b8dac;
}
.blog-details-desc
  .article-leave-comment
  .form-group
  .form-control::-ms-input-placeholder {
  color: #9b8dac;
}
.blog-details-desc
  .article-leave-comment
  .form-group
  .form-control::placeholder {
  color: #9b8dac;
}
.blog-details-desc .article-leave-comment .form-group .form-control:focus {
  border: 1px solid var(--main-color);
}
.blog-details-desc
  .article-leave-comment
  .form-group
  .form-control:focus::-webkit-input-placeholder {
  color: transparent;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}
.blog-details-desc
  .article-leave-comment
  .form-group
  .form-control:focus:-ms-input-placeholder {
  color: transparent;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}
.blog-details-desc
  .article-leave-comment
  .form-group
  .form-control:focus::-ms-input-placeholder {
  color: transparent;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}
.blog-details-desc
  .article-leave-comment
  .form-group
  .form-control:focus::placeholder {
  color: transparent;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}
.blog-details-desc .article-leave-comment .form-group textarea.form-control {
  min-height: 115px;
}
.blog-details-desc .article-leave-comment .form-cookies-consent a:hover {
  color: var(--optional-color);
}
.blog-details-desc
  .article-leave-comment
  .form-cookies-consent
  [type="checkbox"]:checked
  + label,
.blog-details-desc
  .article-leave-comment
  .form-cookies-consent
  [type="checkbox"]:not(:checked)
  + label {
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  display: inline-block;
  margin-bottom: 0;
  color: var(--paragraph-color);
  font-weight: 400;
  font-size: 15px;
}
.blog-details-desc
  .article-leave-comment
  .form-cookies-consent
  [type="checkbox"]:checked
  + label:before,
.blog-details-desc
  .article-leave-comment
  .form-cookies-consent
  [type="checkbox"]:not(:checked)
  + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 5px;
  width: 15px;
  height: 15px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  border: 1px solid #ddd;
  border-radius: 5px;
  background: var(--white-color);
}
.blog-details-desc
  .article-leave-comment
  .form-cookies-consent
  [type="checkbox"]:checked
  + label:after,
.blog-details-desc
  .article-leave-comment
  .form-cookies-consent
  [type="checkbox"]:not(:checked)
  + label:after {
  content: "";
  width: 5px;
  height: 5px;
  background: var(--main-color);
  position: absolute;
  top: 10.5px;
  left: 5px;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  border-radius: 30px;
}
.blog-details-desc
  .article-leave-comment
  .form-cookies-consent
  [type="checkbox"]:not(:checked)
  + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.blog-details-desc
  .article-leave-comment
  .form-cookies-consent
  [type="checkbox"]:checked
  + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.blog-details-desc
  .article-leave-comment
  .form-cookies-consent
  [type="checkbox"]:checked
  + label:before,
.blog-details-desc
  .article-leave-comment
  .form-cookies-consent
  [type="checkbox"]:hover
  + label:before {
  border-color: var(--main-color);
}
.blog-details-desc .article-leave-comment .form-cookies-consent p {
  display: inline-block;
  margin-bottom: 0;
  margin-right: 25px;
}
.blog-details-desc .article-leave-comment .default-btn,
.but88 {
  border: none;
}
.widget-area .widget_categories .list {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.widget-area .widget_categories .list li {
  margin-bottom: 18px;
}
.widget-area .widget_categories .list li a {
  color: #000;
  display: inline-block;
  font-weight: 500;
  padding: 15px 25px;
  background-color: var(--white-color);
  -webkit-box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
  box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
  border-radius: 30px;
  width: 100%;
}
.widget-area .widget_recent_post .item {
  overflow: hidden;
  margin-bottom: 25px;
}
.widget-area .widget_recent_post .item .thumb {
  float: left;
  height: 90px;
  overflow: hidden;
  display: block;
  position: relative;
  width: 90px;
  margin-right: 15px;
  z-index: 1;
}
.widget-area .widget_recent_post .item .thumb .fullimage {
  width: 90px;
  height: 90px;
  display: inline-block;
  border-radius: 10px;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center center !important;
}
.widget-area .widget_recent_post .item .thumb::after,
.widget-area .widget_recent_post .item .thumb::before {
  -webkit-transition: 0.8s cubic-bezier(0.2, 1, 0.22, 1);
  transition: 0.8s cubic-bezier(0.2, 1, 0.22, 1);
  content: "";
  background-color: #fff;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.widget-area .widget_recent_post .item .thumb::before {
  width: 40px;
  height: 1px;
  left: 100%;
}
.widget-area .widget_recent_post .item .thumb::after {
  height: 40px;
  width: 1px;
  top: 0;
}
.widget-area .widget_recent_post .item .info {
  overflow: hidden;
  margin-top: 2.5px;
}
.widget-area .widget_recent_post .item .info span {
  display: block;
  color: #00a3e8;
  margin-bottom: 12px;
  font-size: 15px;
  font-weight: 500;
}
.widget-area .widget_recent_post .item .info .title {
  margin-bottom: 0;
  line-height: 1.5;
  font-size: 16px;
  font-weight: 600;
}
.widget-area .widget_recent_post .item .info .title a {
  display: inline-block;
  color: #000;
}
.widget-area .widget_recent_post .item:hover .thumb::after,
.widget-area .widget_recent_post .item:hover .thumb::before {
  opacity: 1;
  top: 50%;
  left: 50%;
}
.single-blog-card .blog-content .blog-btn {
  color: #00a3e8;
  font-weight: 600;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  position: relative;
  margin-top: 15px;
  background: 0 0;
  border: none;
}
.single-blog-card .blog-content .blog-btn::before {
  content: "";
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  width: 20px;
  height: 1.5px;
  background: #00a3e8;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-radius: 5px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}
.widget-area .widget .widget-title {
  margin-bottom: 25px;
  position: relative;
  padding-bottom: 15px;
  font-size: 20px;
  font-weight: 700;
}
.widget-area .widget .widget-title::before {
  content: "";
  position: absolute;
  left: 0;
  width: 50px;
  height: 1px;
  bottom: -1px;
  background-color: #00a3e8;
}
.our-team {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 50px;
}
.our-team .pic {
  border-radius: 50%;
  overflow: hidden;
  position: relative;
}
.our-team .pic:after,
.our-team .pic:before {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 13px solid #e6e5e5;
  position: absolute;
  top: 0;
  left: 0;
}
.our-team .pic:after {
  border-color: #37b0f1 #e6e5e5 #e6e5e5 #37b0f1;
  z-index: 1;
  transform: rotate(-10deg);
  transition: 0.5s;
}
.our-team:hover .pic:after {
  transform: rotate(350deg);
}
.our-team img {
  width: 100%;
  height: auto;
}
.our-team .title {
  font-size: 18px;
  font-weight: 700;
  color: #222;
  padding-bottom: 10px;
  margin: 15px 0 10px;
  position: relative;
}
.our-team .title:after {
  content: "";
  width: 30px;
  height: 2px;
  background: #222;
  margin: 0 auto;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.our-team .post {
  display: block;
  font-size: 16px;
  color: #00a3e8;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.our-team .icon11 {
  padding: 0;
  margin: 10px 0 0;
}
.our-team .icon11 li a {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50%;
  background: #e0e0e0;
  font-size: 14px;
  color: #999;
  margin-right: 5px;
  transition: 0.3s ease-out;
}
.our-team .icon11 li a:hover {
  background: #37b0f1;
  color: #fff;
}
.servicepage .service-wrap {
  height: 480px;
}
.servicepage .desc {
  font-size: 13px;
}
.about-box-inner:hover,
/* .servicepage .service-wrap:hover {
  -webkit-transform: scale(1.03);
  -moz-transform: scale(1.03);
  -o-transform: scale(1.03);
  -ms-transform: scale(1.03);
  transform: scale(1.03);
  border: 2px solid #db2a1973;
} */
.mb-65 {
  margin-bottom: 65px;
}
.our-service-area::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #030749;
  opacity: 0.8;
  z-index: -1;
}
.our-service-area::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 400px;
  background-color: #fff;
  z-index: -1;
}
.our-service-area .default-btn {
  margin: 30px auto 0;
  display: table;
}
.resume-item,
.resume-item .body {
  display: -webkit-box;
  display: -ms-flexbox;
}
.single-services {
  background-color: #fff;
  -webkit-box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.08);
  padding: 20px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin-bottom: 30px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  height: 260px;
}
.single-services::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  background-image: url(../../public/images/shape1.png);
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position-x: right;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.single-services::after {
  position: absolute;
  bottom: 8px;
  right: 8px;
  width: 0;
  height: 0;
  content: "";
  border-top: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 20px solid #dfdfdf;
  font-size: 20px;
  z-index: -1;
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg);
}
.single-services:hover {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}
.single-services:hover::before {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  opacity: 0.4;
}
.single-services h3 {
  font-size: 16px;
  color: #000;
  margin: 0 0 6px;
}
.single-services img {
  margin-right: 10px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}
.single-services p {
  margin-bottom: 20px;
  font-size: 14px;
}
.service-d-img::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #05125e;
  content: "";
  opacity: 0.5;
}
.resume-item:hover .icon-outer-line span,
.technology-item,
.technology-item:hover,
.technology-item:hover .icontech img {
  background: #171718;
}
.technology-item {
  position: relative;
  text-align: center;
  margin-bottom: 80px;
  border-radius: 50px;
  color: #fff;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  padding: 30px 25px 50px;
  -webkit-box-shadow: 0 15px 30px 0 rgba(255, 69, 0, 0.15);
  box-shadow: 0 15px 30px 0 rgba(255, 69, 0, 0.15);
  height: 477px;
}
.technology-item .technology-bg-img {
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  visibility: hidden;
  position: absolute;
  border-radius: 50px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-transform: scale(0.7);
  transform: scale(0.7);
}
.technology-item .bodytech {
  z-index: 1;
  position: relative;
}
.technology-item .bodytech h4 {
  font-size: 50px;
  margin-bottom: 10px;
  color: #00a3e8;
}
.technology-item .bodytech h5 {
  margin-bottom: 15px;
  color: #00a3e8;
}
.technology-item .bodytech a,
.technology-item .bodytech i {
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.technology-item .bodytech a {
  font-weight: 600;
  color: #171718;
  text-transform: uppercase;
}
.technology-item .bodytech a i,
.technology-item:hover .bodytech a i {
  margin-left: 5px;
  color: #ff4500;
}
.technology-item .bodytech a:hover,
.technology-item:hover .bodytech a:hover {
  color: #ff4500;
  text-decoration: underline;
}
.technology-item .bodytech a:hover i,
.technology-item:hover .bodytech a:hover i {
  margin-left: 10px;
}
.technology-item .icontech {
  left: 50%;
  bottom: -30px;
  position: absolute;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.technology-item .icontech img {
  width: 60px;
  height: 60px;
  padding: 3px;
  position: relative;
  border-radius: 50%;
  background: #00a3e8;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.technology-item .icontech-border {
  left: 50%;
  bottom: 0;
  position: absolute;
  margin-left: 30px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.technology-item .icontech-border:after,
.technology-item .icontech-border:before {
  top: 0;
  content: "";
  position: absolute;
  border-radius: 50%;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  border: 2px solid #00a3e8;
}
.technology-item .icontech-border:after {
  right: -10px;
  width: 80px;
  height: 80px;
  line-height: 80px;
}
.technology-item .icontech-border:before {
  right: -20px;
  width: 100px;
  height: 100px;
  line-height: 100px;
}
.technology-item:hover .technology-bg-img {
  opacity: 0.15;
  visibility: visible;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.resume-item:hover .icon-outer-line,
.technology-item:hover .icontech-border:after,
.technology-item:hover .icontech-border:before {
  border: 2px solid #171718;
}
.technology-item:hover .bodytech p {
  opacity: 0.75;
}
.technology-detail-top img {
  border-radius: 30px;
}
.technology-detail-top span {
  bottom: -60px;
  left: 0;
  width: 120px;
  height: 120px;
  color: #fff;
  line-height: 100px;
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  border-radius: 50%;
  background: #00a3e8;
  border: 10px solid #fff;
  position: absolute;
}
.technology-detail-inner {
  margin-top: 70px;
}
.resume-item {
  padding: 30px;
  display: flex;
  margin-bottom: 24px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50px;
  background: #fff;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-shadow: 0 15px 30px 0 rgba(255, 69, 0, 0.15);
  box-shadow: 0 15px 30px 0 rgba(255, 69, 0, 0.15);
}
.resume-item .body .text h6 {
  margin-top: 10px;
  color: #000;
  text-transform: uppercase;
  font-size: 16px;
}
.resume-item .body .text span {
  font-weight: 600;
  color: #00a3e8;
  text-transform: uppercase;
}
.resume-item .body .icon-outer-line {
  padding: 7px;
  height: 80px;
  width: 80px;
  margin-right: 20px;
  border-radius: 50%;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border: 2px solid #00a3e8;
}
.resume-item .body .icon-outer-line span {
  width: 60px;
  height: 60px;
  font-size: 25px;
  color: #fff;
  text-align: center;
  line-height: 60px;
  border-radius: 50%;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background: #00a3e8;
}
.resume-item:hover {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
  -webkit-box-shadow: 0 15px 30px 0 rgba(255, 69, 0, 0.2);
  box-shadow: 0 15px 30px 0 rgba(255, 69, 0, 0.2);
}
@media only screen and (max-width: 575.98px) {
  .resume-item .body {
    display: block;
  }
  .resume-item .body .text {
    margin-top: 15px;
  }
  .resume-item .body .icon-outer-line {
    margin: 0;
  }
}
.job-wrapper {
  border: 1px solid #ebebeb;
  padding: 45px 20px;
  background: #fff;
  position: relative;
  box-shadow: 0 6px 25px rgb(12 89 219 / 9%);
  height: 432px;
}
.job-content,
.job-instructor-profile,
.job-tag {
  position: relative;
  z-index: 2;
}
div.job-tag span {
  background: #f5f7fc;
  margin-right: 10px;
  padding: 5px 10px;
  border-radius: 5px;
  display: inline-block;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;
  color: #26ae61;
  line-height: 1;
  min-height: 25px;
}
.job-tag span.tag-urgent {
  background: #ff4545;
  color: #fff;
}
.job-tag span.tag-fea {
  background: #26ae61;
  color: #fff;
}
.job-instructor-img {
  margin-right: 20px;
  float: left;
}
@media (max-width: 767px) {
  .job-content p {
    height: 170px !important;
  }
  .job-wrapper {
    padding: 25px;
    height: 460px;
  }
  .job-instructor-img-2 {
    float: none;
    margin-bottom: 20px;
  }
}
.forgot a,
.p-viewer {
  float: right;
}
.job-instructor-title h4 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 6px;
  color: #00a3e8;
}
.job-content h4:hover,
.job-instructor-title h4:hover,
.job-instructor-title h5:hover,
.job-meta-2 span i {
  color: #26ae61;
}
.job-instructor-title span {
  color: #667488;
  font-size: 14px;
}
.job-instructor-title span i,
.job-salary i {
  color: #26ae61;
  margin-right: 5px;
  font-size: 18px;
}
.job-content h4 {
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 15px;
}
.job-content p {
  margin-bottom: 25px;
  height: 150px;
}
.job-salary span {
  margin-right: 50px;
  font-weight: 500;
  font-size: 15px;
}
.job-salary a {
  font-weight: 700;
  font-size: 14px;
  color: #26ae61;
}
.job-salary a i {
  margin-left: 5px;
  transform: translateY(0);
  display: inline-block;
  font-size: 13px;
}
.banck-icon {
  position: absolute;
  right: 30px;
  z-index: 1;
  top: 30px;
}
.banck-icon i {
  font-size: 100px;
  line-height: 1;
  color: #f7f7f7;
}
.forgot a,
.signup a {
  color: red;
}
.job-meta span {
  margin-right: 15px;
}
.job-meta-2 span {
  display: block;
  margin-bottom: 15px;
}
.cont22 p {
  padding-right: 29px;
  text-align: justify;
}
.term12 ul {
  margin: 10px;
}
.term12 ul li::before {
  content: "\2022";
  color: #00a3e8;
  font-weight: 700;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
.term12 ul li {
  line-height: 30px;
  margin-bottom: 20px;
  font-size: 17px;
}
.alice-carousel__next-btn-item,
.alice-carousel__prev-btn-item {
  display: none !important;
}
.itemteam img {
  border-radius: 50%;
  width: 50%;  
}
.text88 {
  z-index: 1;
}
.navbar-brand img {
  width: 180px;
}
.navbar-collapse {
  flex-grow: unset;
}
.navbar-light .navbar-nav .nav-link {
  margin: 0 10px;
  color: #000 !important;
}
@media (max-width: 768px){
  .navbar-light .navbar-nav .nav-link {
    margin: 0px;
  }
  .rounded-pill{
    /* color: white !important; */
    padding: 2px 2px 2px 5px !important
  }
}

@media (max-width: 480px){
  .rounded-pill{
    font-size: small;
    padding: 2px 2px 2px 5px !important
  }
}
@media (max-width: 767px) {
  .cookiepara,
  .enquirebtn {
    font-size: 12px;
  }
  .job-salary span {
    margin-right: 10px;
  }
  .rs-slider.slider3 img {
    height: 310px;
  }
  .navihead {
    /* padding-left: 10px;
    padding-right: 10px; */
    padding: 0px, 5px;
  }
  .full-width-header
    .rs-header
    .expand-btn-inner
    ul
    .humburger
    .nav-expander
    .bar {
    display: -webkit-inline-box;
  }
  .rs-footer.style1 .footer-top .footer-title,
  .servicepage .service-wrap {
    margin-top: 20px;
  }
  .enquirebtn {
    padding: 6px 10px 6px 5px;
    margin-left: 2px;
  }
  .rs-testimonial.style1 .pattern-img .common,
  .wave {
    display: none;
  }
  .pb-133,
  .pb-95 {
    padding-bottom: 20px;
  }
  .pt-133,
  .pt-95 {
    padding-top: 20px;
  }
  .client23 img {
    width: 100px;
  }
  .single-services {
    height: auto;
  }
  .blog-details-desc .article-share .share-social {
    margin-top: 20px;
    text-align: start !important;
  }
  .blog-details-desc .article-comments .comments-list {
    padding-left: 0;
  }
  .blog-details-desc .article-comments .comments-list img {
    position: relative;
    margin-bottom: 22px;
  }
  .rs-appointment.style1 .appoint-schedule {
    padding: 10px;
  }
  .rs-contact.contact-style2 .contact-map {
    padding-right: 0;
    margin-bottom: 37px;
  }
  .container-fluid {
    padding-left: 30px;
    padding-right: 30px;
  }
  .loginbtn {
    /* padding: 3px 20px 6px !important; */
  }
  .rs-slider.slider3 .content-part {
    top: 43%;
  }
  .rs-slider.rs-slider-style3.slider3 .content-part .sl-title {
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 18px;
  }
  .rs-slider.rs-slider-style3.slider3 .content-part .sl-subtitle {
    font-size: 18px;
  }
  .technology-item {
    height: 600px;
  }
  .cokkiebtn {
    margin-bottom: 0;
  }
  .readoncookie {
    padding: 0;
  }
}
.alice-carousel__dots {
  margin: 20px 3px 5px !important;
}
.widget-area .widget_recent_post .item .thumb .fullimage.bg1 {
  background-image: url(../../public/images/blog-1.jpg);
}
.widget-area .widget_recent_post .item .thumb .fullimage.bg2 {
  background-image: url(../../public/images/blog-2.jpg);
}
.widget-area .widget_recent_post .item .thumb .fullimage.bg3 {
  background-image: url(../../public/images/blog-3.jpg);
}
.marq1 div {
  --gradient-color: rgb(255 255 255 / 0%), rgba(255, 255, 255, 0) !important;
}
.marq1 div img {
  margin: 40px 20px;
  width: 120px;
}
.pt-25 {
  padding-top: 25px;
}
.resume1 {
  font-size: 24px;
}
.bg-light {
  background-color: #ebebed !important;
  box-shadow: rgb(193 193 197 / 28%) 0 7px 29px 0;
  border-bottom: 2px solid #00a3e8;
}
.about-box-inner {
  background-color: #fff;
  text-align: left;
  padding: 50px 10px 10px;
  border-style: solid;
  border-width: 0;
  box-shadow: 0 0 10px 0 #eee;
  margin: 10px;
  height: 357px;
}
.about-box-inner
  .flip-box-wrap
  .front-part
  .front-content-part
  .front-title-part
  .title {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 10px;
  margin-top: 15px;
}
.demopricing {
  padding-top: 95px;
  padding-bottom: 25px;
}
.pricingTable {
  color: #9b9b9d;
  background: repeating-linear-gradient(
    45deg,
    #fff,
    #fff 20px,
    #fcfcfc 20px,
    #fcfcfc 40px
  );
  font-family: "Open Sans", sans-serif;
  padding: 45px 0 40px;
  border-radius: 50px/80px;
  box-shadow: 0 0 15px -2px rgba(0, 0, 0, 0.2);
  outline: #00a3e8 solid 4px;
  outline-offset: -12px;
  position: relative;
  overflow: hidden;
}
.pricingTable:before {
  content: "";
  background: #00a3e8;
  width: 150px;
  height: 50px;
  border: 10px solid #fff;
  border-radius: 50px;
  transform: translateX(-50%) translateY(0);
  position: absolute;
  top: -25px;
  left: 50%;
}
.pricingTable .price-value,
.pricingTable .pricingTable-signup a {
  border-radius: 50px;
  background: #00a3e8;
  color: #fff;
  display: inline-block;
}
.pricingTable .title {
  font-size: 30px;
  font-weight: 500;
  text-transform: uppercase;
  margin: 0 0 25px;
}
.pricingTable .price-value {
  padding: 12px 50px;
  margin: 0 0 25px;
}
.pricingTable .price-value .amount {
  font-size: 35px;
  line-height: 35px;
  font-weight: 600;
  margin: 0 0 3px;
  display: block;
}
.pricingTable .price-value .duration {
  font-size: 14px;
  line-height: 14px;
  display: block;
}
.pricingTable .pricing-content {
  padding: 0;
  margin: 0 0 25px;
  list-style: none;
}
.pricingTable .pricing-content li {
  font-size: 17px;
  font-weight: 500;
  line-height: 30px;
  margin: 0 0 12px;
}
.pricingTable .pricingTable-signup a {
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 6px 15px;
  transition: 0.3s;
}
.pricingTable .pricingTable-signup a:hover {
  text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.6);
  box-shadow: 5px 5px rgba(0, 0, 0, 0.2);
}
.pricingTable.blue {
  outline-color: #28b9c1;
}
.pricingTable.blue .price-value,
.pricingTable.blue .pricingTable-signup a,
.pricingTable.blue:before {
  background-color: #28b9c1;
}
.pricingTable.green {
  outline-color: #3ea394;
}
.pricingTable.green .price-value,
.pricingTable.green .pricingTable-signup a,
.pricingTable.green:before {
  background-color: #3ea394;
}
@media only screen and (max-width: 990px) {
  .our-team {
    margin-bottom: 30px;
  }
  .pricingTable {
    margin: 0 0 40px;
  }
}
.loginbtn {
  padding: 10px 20px;
  color: #fff;
  font-weight: 600;
  border-radius: 20px;
}
.contentsubs {
  height: 40px;
  box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}
.subscription {
  position: relative;
  width: 100%;
  height: 100%;
}
.subscription .add-email {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: 0;
  padding: 0 20px;
}
.subscription .submit-email {
  position: absolute;
  top: 0;
  right: 0;
  height: calc(100% - 2px);
  width: 100px;
  border: none;
  border-radius: 0;
  outline: 0;
  margin: 1px;
  padding: 0 20px;
  cursor: pointer;
  background: #00a3e8;
  color: #fff;
  transition: width 0.35s ease-in-out, background 0.35s ease-in-out;
}
.subscription.done .submit-email {
  width: calc(100% - 2px);
  background: #c0e02e;
}
.subscription .submit-email .before-submit {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 38px;
  transition: visibility 0.35s ease-in-out, opacity 0.35s ease-in-out;
}
.navtrh .dropdown-menu.show {
  display: block;
  border-top: 3px solid #00a3e8;
  margin-top: 10px;
  width: 200px;
}
.navtrh .dropdown-item {
  padding: 10px 15px;
  font-weight: 600;
}
.dropdown-item:active,
.navtrh .dropdown-item.active {
  color: #fff;
  text-decoration: none;
  background-color: #00a3e8;
}
.set-abs {
  position: absolute;
  right: 2%;
}
.background-animate {
  -webkit-animation: 150s linear infinite movebounce;
  animation: 150s linear infinite movebounce;
  bottom: 0;
  width: 100%;
}
@keyframes movebounce {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(1turn);
  }
}
.occu {
  border-radius: 20px;
  box-shadow: 0 6px 25px rgb(12 89 219 / 9%);
  padding: 10px;
  margin: 10px 0;
  height: auto !important;
}
.profile-head h6 {
  color: #0062cc;
}
.profile-edit-btn {
  border: none;
  border-radius: 1.5rem;
  width: 70%;
  padding: 2%;
  font-weight: 600;
  color: #6c757d;
  cursor: pointer;
}
.proile-rating {
  font-size: 12px;
  color: #818182;
  margin-top: 5%;
}
.proile-rating span {
  color: #495057;
  font-size: 15px;
  font-weight: 600;
}
.profile-head .nav-tabs {
  margin-bottom: 5%;
}
.profile-head .nav-tabs .nav-link {
  font-weight: 600;
  border: none;
}
.profile-head .nav-tabs .nav-link.active {
  border: none;
  border-bottom: 2px solid #0062cc;
}
.profile-work {
  padding: 14%;
  margin-top: -15%;
}
.profile-work p {
  font-size: 12px;
  color: #818182;
  font-weight: 600;
  margin-top: 10%;
}
.profile-work a {
  text-decoration: none;
  color: #495057;
  font-weight: 600;
  font-size: 14px;
}
.profile-tab label {
  font-weight: 600;
}
.profile-tab p {
  font-weight: 600;
  color: #0062cc;
}
.serviceindustry .service-wrap {
  height: 446px !important;
}
.blogbtn,
.blogcomment button {
  color: #fff;
  font-weight: 600;
  transition: var(--transition);
  position: relative;
  margin-top: 15px;
  background: #000;
  padding: 10px;
  border: none;
  border-radius: 20px;
}
.viewbutton {
  background: #fff;
  border: none;
}
.loader,
.subbtn {
  background-color: #00a3e8;
}
.accordion > input[type="checkbox"] {
  position: absolute;
  left: -100vw;
}
.accordion .content {
  overflow-y: hidden;
  height: 0;
  transition: height 0.3s;
}
.accordion > input[type="checkbox"]:checked ~ .content {
  height: auto;
  overflow: visible;
  padding: 15px;
  border: 1px solid #e8e8e8;
  border-top: 0;
}
.accordion label {
  display: block;
  color: #fff;
  cursor: pointer;
  font-weight: 600;
  padding: 15px;
  background: #367fe9;
}
.accordion {
  margin-bottom: 1em;
  box-shadow: rgb(0 0 0 / 16%) 0 1px 4px;
}
.accordion .handle {
  margin: 0;
  font-size: 1.125em;
  line-height: 1.2em;
}
.accordion .handle label:before {
  font-family: fontawesome;
  content: "\f054";
  display: inline-block;
  margin-right: 10px;
  font-size: 0.58em;
  line-height: 1.556em;
  vertical-align: middle;
}
.accordion > input[type="checkbox"]:checked ~ .handle label:before {
  content: "\f078";
}
.faqlist ul {
  list-style: square;
  margin: 10px;
}
.faqlist ul li {
  line-height: 30px;
  margin-bottom: 20px;
  font-size: 15px;
  font-weight: 500;
}
.validation {
  margin-top: -24px;
}
.p-viewer {
  position: relative;
  margin: -67px 10px;
}
.certifite img {
  border: 3px solid #d2d9dc;
  margin-top: 47px;
  box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
}
#notfound {
  position: relative;
  height: 100vh;
}
#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.notfound {
  max-width: 520px;
  width: 100%;
  line-height: 1.4;
}
.notfound .notfound-404 {
  position: relative;
  height: 240px;
}
.loading,
.loading:before {
  position: fixed;
  top: 0;
  left: 0;
}
.notfound .notfound-404 h1 {
  font-family: Montserrat, sans-serif;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 252px;
  font-weight: 900;
  margin: 0 0 0 -20px;
  color: #262626;
  text-transform: uppercase;
  letter-spacing: -40px;
}
.notfound .notfound-404 h3,
.notfound h2 {
  font-family: Cabin, sans-serif;
  text-transform: uppercase;
}
.notfound .notfound-404 h1 > span {
  text-shadow: -8px 0 0 #fff;
}
.notfound .notfound-404 h3 {
  position: relative;
  font-size: 16px;
  font-weight: 700;
  color: #262626;
  margin: 0;
  letter-spacing: 3px;
  padding-left: 6px;
}
.notfound h2 {
  font-size: 20px;
  font-weight: 400;
  color: #000;
  margin-top: 0;
  margin-bottom: 25px;
}
@media only screen and (max-width: 767px) {
  .notfound .notfound-404 {
    height: 200px;
  }
  .notfound .notfound-404 h1 {
    font-size: 200px;
  }
}
@media only screen and (max-width: 480px) {
  .notfound .notfound-404 {
    height: 162px;
  }
  .notfound .notfound-404 h1 {
    font-size: 162px;
    height: 150px;
    line-height: 162px;
  }
  .notfound h2 {
    font-size: 16px;
  }
}
.site-map .footer-title h4 {
  box-shadow: 0 2px 11px 0 rgb(0 0 0 / 8%);
}
.site-map .footer-title h4 a {
  padding: 15px 10px;
  color: #000;
  font-weight: 500 !important;
  font-size: 16px;
}
.subbtn {
  color: #fff;
  margin-top: 20px;
  padding: 5px;
}
.loading {
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  bottom: 0;
  right: 0;
}
.loading:before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}
.loading:not(:required) {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.loading:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: 1.5s linear infinite spinner;
  -moz-animation: 1.5s linear infinite spinner;
  -ms-animation: spinner 1500ms infinite linear;
  -o-animation: 1.5s linear infinite spinner;
  animation: 1.5s linear infinite spinner;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0,
    rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0,
    rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.5) -1.5em 0 0 0,
    rgba(0, 0, 0, 0.5) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0,
    rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(0, 0, 0, 0.75) 1.5em 0 0 0,
    rgba(0, 0, 0, 0.75) 1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) 0 1.5em 0 0,
    rgba(0, 0, 0, 0.75) -1.1em 1.1em 0 0, rgba(0, 0, 0, 0.75) -1.5em 0 0 0,
    rgba(0, 0, 0, 0.75) -1.1em -1.1em 0 0, rgba(0, 0, 0, 0.75) 0 -1.5em 0 0,
    rgba(0, 0, 0, 0.75) 1.1em -1.1em 0 0;
}
@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.headingcolor {
  color: #00acee;
}
.createtext {
  font-size: 14px;
}
.validation {
  color: red;
  font-weight: 500;
  font-size: 15px;
}
@media(max-width:767px){
  .rs-testimonial.style1 .item .author-detail .desc{
   margin:0 auto 65px;
  }
}
/* a{
	text-decoration:none;
}
 
.services{
	width:100%;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
}
 
 
.service-item-container{
	width:100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.service-item{
	display:flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width:300px;
	padding: 20px 25px;
	height: 400px;
	box-sizing: border-box;
	margin: 30px;
	position: relative;
	background: #fff;
	border-radius: 10px;
	box-shadow: 0 1px 2px rgba(122, 119, 119, 0.07);
	cursor: pointer;
}
.service-item img{
	height: 75px;
}
.service-item h3{
text-align: center;
}
.service-item p{
	color: rgba(87,105,117,0.90);
	text-align: center;
}
.button{
	width: 140px;
	height: 40px;
	border-radius:5px;
	border:1px solid rgba(74,144,226,0.50);
	display: flex;
	justify-content: center;
	align-items: center;
	color:#576975;
	margin-top:10px; 
}
.bar{
	width: 200px;
	height: 6px;
	position: absolute;
	left: 50%;
	top: 0%;
	transform: translateX(-50%);
	background-color:#4a90e2; 
	border-radius: 0px 0px 10px 10px;
	display: none;
	animation: bar 0.5s;
}
.service-item:hover{
	box-shadow: 2px 2px 30px rgba(0,0,0,0.08);
	transition: all ease 0.3s;
}
.button:hover{
	background-color:#4a90e2;
	border: 1px solid #4a90e2;
	color:#FFFFFF;
	transition: all ease 0.3s;
}
.service-item:hover .bar{
	display: block;
}
@keyframes bar{
	0%{
		width:0px;
	}
	100%{
		width:200px;
	}
}
@media(max-width:1050px){
	.service-item-container{
		flex-wrap: wrap;
		
	}	
	.services{
		height: auto;
	}
	.s-heading{
		margin: 15px;
	}
	.service-item{
		flex-grow: 1;
	}
	
} */

#overlapthis {

  background-image: url(../../public/images/Mantanance8.png);

  height: 302px;
left: 277px;
position: absolute;
top: 173px;
width: 500px;
}

#thebigimage {
  background-image: url(../../public/images/maintanance1.jpg);
  height: 265px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  width: 371px;
} 
.fireclient{

width: 20vh;
height: 20vh;
border-radius: 50%;
}

.cardimges{
  transition: all 1s ease;
}

.cardimges:hover{
  transform: scale(1.1);
}
@media (max-width: 767px) {
  .loginbtn{
    /* margin-left: 25px; */
    font-size: 13px;
  }


  }
  @media (max-width: 768px) {

    .enquirebtn {
      
      padding: 5px;
      margin-left: 2px;
  }

  }
  @media (max-width: 768px) {

    .loginbtn{
      padding: 5px 22px 6px 22px;
      /* margin-left: 0.5rem; */
  }

  }
  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
  
  }
  .whatsappbuttn {background: none;
    border: navajowhite;
    color: #fff;}
  /* div.text {
    display: grid;
    place-items: center;
    height: 100vh;
    background: linear-gradient(-45deg, #8360c3, #8360c3, #2ebf91, #2ebf91);
    background-size: 300%;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 5rem;
    letter-spacing: -5px;
    text-transform: uppercase;  
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: animated_text 10s ease-in-out infinite;
    -moz-animation: animated_text 10s ease-in-out infinite;
    -webkit-animation: animated_text 10s ease-in-out infinite;
  }
  
  @keyframes animated_text {
    0% { background-position: 0px 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0px 50%; }
  }
   */


   /*------------------------------------------------------------------------------------------- */
   .banner{
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100%;
    margin-bottom: 20px;
   }

   .cover_banner{
    width: 100%;
    height: 100%;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    padding: 4rem;
   }
   .text_content{
    width: 60%;
   }
   .title_text{
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 16px;
    color: #05a4ec;
   }
   .title_discp{
    font-size: large;
    font-weight: 500;
    display: block;
   }
   .get_btn_btn{
    margin-top: 36px;
    padding: 15px 20px;
    outline: none;
    border: none;
    border-radius: 3%;
    background: #05a4ec;
    color: white;
    font-size: large;
    font-weight: 500;
   }

   @media (max-width: 480px) {
    .banner{
      height: auto;
      padding-top: 7rem;
      padding-bottom: 4rem;
    }
    .cover_banner{
      justify-content: center;
      align-items: center;
      padding: 0px;
     }
    .text_content {
      width: 100%;
      text-align: center;
    }
  
    .title_text {
      font-size: 1.5rem;
      padding: 0px 5px;
    }
  
    .title_discp {
      font-size: 0.9rem;
      padding: 0px 5px;
    }
  
    .get_btn_btn {
      font-size: medium;
      padding: 10px 14px;
    }
  }

   @media (max-width: 992px) {  
    .banner{
      height: auto;
      padding-top: 7rem;
      padding-bottom: 4rem;
    }
    .cover_banner{
      display: flex;
      justify-content: center;
     }
    .text_content {
      width: 90%;
      text-align: center;
    }
    .title_text {
      font-size: 2rem;
    }
    .get_btn_btn {
      font-size: medium;
      padding: 12px 16px;
    }
  }
  
  

  .cardShadow{
    box-shadow:
    0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048),
    0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072),
    0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
    border-radius: 5%;
  }
  .service-wrap{
    box-shadow:
  0 2.8px 2.2px rgba(0, 0, 0, 0.034),
  0 6.7px 5.3px rgba(0, 0, 0, 0.048),
  0 12.5px 10px rgba(0, 0, 0, 0.06),
  0 22.3px 17.9px rgba(0, 0, 0, 0.072),
  0 41.8px 33.4px rgba(0, 0, 0, 0.086),
  0 100px 80px rgba(0, 0, 0, 0.12)
 !important;
  }
  .job-card{
    box-shadow:
    0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048),
    0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072),
    0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
  }
  
  @media(max-width : 480px){
    .footer-links{
      font-weight: lighter !important;
      line-height: 1.3;
    }
  }
  .portfolio-div{
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: space-between; */
   
  }

 @media (min-width:992px) {
  .nav-item.dropdown:hover .dropdown-menu {
    display: block;
    border-top: 3px solid #05a4ec;
  }
  
}
.nav-item:hover .nav-link {
  color: #05a4ec !important; /* Change link color on hover if desired */
}

.portfolio-div .our-work-heading:hover {
  color: #05a4ec !important; /* Change link color on hover if desired */
}

.captcha-box {
  letter-spacing: 30px;
  font-family: 'Courier New', monospace;
  font-size: 24px;
  font-weight: bold;
  padding-left: 20px;
  color: #495057;
  border-radius: 18px;
  user-select: none;
  border: 1px solid #00a3e8;
  background: repeating-linear-gradient(
      45deg,
      #f8f9fa,
      #f8f9fa 10px,
      #e9ecef 10px,
      #e9ecef 20px
  );
  position: relative;
  overflow: hidden;
}

.captcha-box::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(#00a3e8 1px, transparent 1px);
  background-size: 10px 10px;
  opacity: 0.2; 
  pointer-events: none; 
}

@media only screen and (max-width: 991px) {
.captcha-box {
    display: inline-flex !important;
}
}

@media only screen and (max-width: 380px) {
.captcha-box {
    width: 250px; 
    padding-left: 0;
}
}

.captcha-custom-btn {
border: 1px solid #00a3e8 !important; 
color: #00a3e8;
background-color: transparent; 
border-radius: 7px;
}

.zoom-in {
transition: transform 0.2s linear;
}

.zoom-in-1:hover {
transform: scale(1.2);
}

.social-icons .icon {
background-color: #ededed;
font-size: 0.95rem;
color: #494848;
padding: 10px;
border-radius: 25px;
}

